import React from 'react';

import APPCONFIG from "constants/Config";
import Framework from "@beardeddevops/react.framework";
import LayawayView from "components/Containers/LayawayView"
import {Tab, Tabs,Card, CardContent} from "@material-ui/core";

import {Collections, Objects} from "components";
import {Button} from "reactstrap";
const breadcrumbsStatic = [
	{
		name: 'Dashboard',
		url: '/dashboard',
		icon: 'dashboard',
	},
	{
		name: 'Layaways',
		url: '/layaways',
		icon: 'hourglass',
	}
];

export default class Layaway extends Framework.Components.View {

	constructor(props) {
		super(props);
		this.state.tab = 'details'
	}
	handleAfterSave=()=>{
		this.setState({key:Math.random()})
	}
	render() {
		const  {tab} = this.state;
		let breadcrumbs = breadcrumbsStatic.slice().concat([
			{
				name: this.state.name,
				url: `/layaways/${this.props.id}`,
				icon: 'edit',
			}
		]);
		return (
			<div className="container-fluid">
				<Framework.Components.PageBar breadcrumbs={breadcrumbs} history={this.props.history}>
					<Button data-id="add-certificate" onClick={this.toggle('modalPayment')} size={"sm"}
					        color="primary">
						<i className="fa fa-usd"/>&nbsp;&nbsp;Add Payment
					</Button>
				</Framework.Components.PageBar>
				<Tabs value={tab} onChange={this.handleTabChange} component={'div'}>
					<Tab label="Layaway" value="details"/>
					<Tab label="Payments" value="payments"/>
				</Tabs>
				<div style={{display: tab === 'details' ? 'block' : 'none'}}>
					<LayawayView
						key = {this.state.key}
						id={this.props.id}
						history={ this.props.history}

					/>
				</div>
				<div style={{display: tab === 'payments' ? 'block' : 'none'}}>
					<Card>
						<CardContent>
							<Collections.LayawayPayments
								key = {this.state.key}
								parent = {this.props.id}
								history={ this.props.history}
								reloadShell={this.props.reloadShell}
								reloadPage={this.refreshPage}
								reloadSelf={this.state.refresh}
							/>
						</CardContent>
					</Card>

				</div>
				<Framework.Elements.UniversalModal
					// isDev={APPCONFIG.settings.isDev()}
					parent ={this.props.id}
					locations={this.props.locations}
					name={"Layaway"}
					title={'Make Payment'}
					submitLabel={'Submit Payment'}
					object={Objects.Payment}
					history={this.props.history}
					toggle={this.state['modalPayment']}
					reportClose={this.reportClose('modalPayment')}
					afterSave={this.handleAfterSave}
					size={'lg'}
				/>

			</div>
		)
	}
}
