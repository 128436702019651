import React from 'react';

export default class View extends React.Component {

	constructor(props) {
		super(props);

		this.state = {};

		this.setModel = this.setModel.bind(this);
		this.saveModel = this.saveModel.bind(this);
		this.deleteModel = this.deleteModel.bind(this);

		this.toggleModal = this.toggleModal.bind(this);
		this.resetModal = this.resetModal.bind(this);
	}


	setModel(name) {
		this.setState({
			["saveModel" + name]: false,
			["deleteModel" + name]: false,
			["showModal" + name]: false,
			["reloadTable" + name]: false
		});
	}

	saveModel(name) {
		return (override) => {
			console.log('saveModel - ' + "saveModel" + (override || name));
			this.setState({
				["saveModel" + (override || name)]: true
			});
		};
	}

	deleteModel(name) {
		return (override) => {
			this.setState({
				["deleteModel" + (override || name)]: true
			});
		};
	}

	toggleModal(name) {
		return (override) => {
			this.setState({
				["showModal" + (override || name)]: !this.state["showModal" + (override || name)]
			});
		};
	}

	resetModal(name) {
		return (hideModel) => {
			console.log('hideModel - ' + "saveModel" + name);
			this.setState({
				["showModal" + name]: this.state["showModal" + name] && !hideModel,
				["saveModel" + name]: false,
				["deleteModel" + name]: false,
				["reloadTable" + name]: !this.state["reloadTable" + name]
			});
		};
	}

}
