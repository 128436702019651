
import React from "react";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';

import Framework from "@beardeddevops/react.framework";
import Types from "constants/Types";
import moment from "moment";
/**
 *  @property {Skus_Classes_Object} state
 *  @property {object} model
 *  @property {Skus_Classes_Properties} model.properties
 *  @property {Skus_Classes_Is} model.is
 *  @property {Skus_Classes_Has} model.has

 */
 export default class SkuClassObject extends Framework.Components.Object {

	constructor(props) {
		/**
		 *  @typedef {Skus_Classes} this.state
		 */
		super(props, Types.Skus_Classes._name);
	}
	 form = () => {
		 /**
		  * @param {Skus_Classes_Properties} data
		  */
		 let {properties} = this.model;

		 /**
		  *  @type {Skus_Classes_Object} object
		  */
		 let object = this.state;

		 return (
			 <div>
				 <fieldset>
					 <h4 className="section-title">Inventory Groups</h4>
					 <div className="row">
						 <div className="col-md">
 						        <div className="form-group">
                                    <Framework.Elements.TextField
	                                    label={"Name"}
	                                    property={properties.name}
	                                    value={object.name}
	                                    error={object.name_error}
	                                    update={this.handleTextFieldPropertyUpdate}
                                    />
                                </div>
                                <div className="form-group">
	                                {/*TODO: Not Sure what this select needed*/}

                                    {/*<Framework.Elements.Select*/}
	                                {/*    name={"class"}*/}
                                    {/*    label={"Class"}*/}
                                    {/*    property={properties.class}*/}
	                                {/*    value={}*/}
                                    {/*    update={this.handleSelectPropertyUpdate}*/}
                                    {/*    model={Types.Skus_Classes._name}*/}
                                    {/*    nullable={true}*/}

                                    {/*/>*/}
                                </div>
                        </div>
					</div>
				 </fieldset>


				 <div className={classNames("loader", {'active': this.state.loading})}>
					 <ClipLoader color={"#4A4A4A"} loading={this.state.loading}/>
				 </div>
			 </div>
		 )
	 }
 }
