import React from 'react';
import {Route} from 'react-router-dom';
import Framework from '@beardeddevops/react.framework';
import APPCONFIG from 'constants/Config';

const ErrorBoundaryRoute = ({render, children, ...props}) => {
	return (
		<Route {...props} render={(routerProps) => {
			let childrenWithProps = React.cloneElement(children, Object.assign({}, routerProps, routerProps.match.params));
			return (
				<Framework.Errors.General isDev={APPCONFIG.settings.isDev()} {...routerProps}>
					{childrenWithProps}
				</Framework.Errors.General>
			);
		}} />
	);
};

export default ErrorBoundaryRoute;
