import React from "react";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';

import API from "@beardeddevops/react.api";
import APPCONFIG from "../../constants/Config";
import Types from "constants/Types";
import Framework from "@beardeddevops/react.framework";

import ReactTable from "react-table";
import {Link} from "react-router-dom";
import {Card, CardContent} from "@material-ui/core";
import {Button} from "reactstrap";

export default class Users extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Users._name, API.RequestType.READ_ALL);
		super(props, model);
		this.state.limit = 16;
		this.limit = 16;
	}

	render() {
		const {data, pages, limit, loading} = this.state;
		let columns = [
			{
				Header: "Name",
				id: "name_first|name_last",
				accessor: r => r.name_first + ' ' + r.name_last,
				Cell: props => <Link to={"/users/" + props.original.pkey}>{props.value}</Link>
			},
			{
				Header: "Employee ID",
				accessor: "employee_id"
			},
			{
				Header: "Location",
				id: "location",
				accessor: r => r.locations.name,
				Cell: props => <Link
					to={"/locations/" + props.original.locations.pkey}>{props.value}</Link>
			},
			{
				Header: "Email",
				accessor: "email",
			},
			{
				Header: "Status",
				id: "access",
				accessor: r => r.access === null ? 'Deactivated' : 'Active',
			},
			{
				Header: "",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 40,
				Cell: props => {
					return (
						<span>
							<Link to={`/users/` + props.original.pkey}>
								<Button color="green-meadow" size={"sm"} block={true}>
									<i className={"fa fa-pencil"}/></Button>
							</Link>
						</span>
					);
				}
			},
		];
		return (
			<Card>
				<CardContent>
					<div className="table-bar">
						<div className="d-flex">
							{this.renderSearch('Users')}
							{this.renderHeaderRight("Users", columns)}
						</div>
					</div>
					<ReactTable
						key={this.state.key}
						columns={columns}
						manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={data}
						pages={pages} // Display the total number of pages
						loading={loading} // Display the loading overlay when we need it
						loadingText="Requesting Data"
						onFetchData={this.fetchData} // Request new data when things change
						expanderDefaults={{
							sortable: true,
							resizable: true,
							filterable: false,
							width: undefined
						}}
						defaultSorted={[
							{
								id: this.model.sort,
								desc: this.model.order === 'desc'
							}
						]}
						onPageSizeChange={this.onPageSizeChange}
						showPagination={true}
						showPaginationTop={false}
						showPaginationBottom={true}
						showPageSizeOptions={true}
						defaultPageSize={limit}
						className="-striped -highlight"
					/>
					{APPCONFIG.settings.isDev() ? null :
						<div className={classNames("tabled", "loader", {'active': loading})}>
							<ClipLoader color={"#4A4A4A"} loading={loading}/>
						</div>
					}
				</CardContent>
			</Card>
		)
	}
}
