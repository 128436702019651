import React from 'react';
import {Link} from "react-router-dom";
import {withRouter} from 'react-router'

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {Button} from "reactstrap";
import {CardElement, injectStripe, Elements, StripeProvider} from 'react-stripe-elements';

import APPCONFIG from 'constants/Config';
import API from '@beardeddevops/react.api';
import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';

class Register extends Framework.Components.Object {
	constructor(props) {
		let model = new API.BuildClass(Types.Accounts._name, API.RequestType.CREATE);
		super(props, model);

		this.state.step = 1;
		this.state.states = [];
		this.state.processors = [];
		this.brand = APPCONFIG.brand;

		this.handleNext = this.handleNext.bind(this);
		this.handlePrevious = this.handlePrevious.bind(this);
	}

	redirect = "/locations";

	componentDidMount() {
		let States = new API.BuildClass(Types.States._name, API.RequestType.READ_ALL);
		// noinspection JSIgnoredPromiseFromCall
		States.handleSubmit(null, API.RequestType.READ, function (data) {
			// You can retrieve your data however you want, in this case, we will just use some local data.
			this.setState({'states': data.items});
		}.bind(this));
		let Processors = new API.BuildClass(Types.Processors._name, API.RequestType.READ_ALL);
		// noinspection JSIgnoredPromiseFromCall
		Processors.handleSubmit(null, API.RequestType.READ, function (data) {
			// You can retrieve your data however you want, in this case, we will just use some local data.
			this.setState({'processors': data.items});
		}.bind(this));
	}

	handleProcess = event => {
		let shouldContinue = API.Validator.process(this, this.requestType,true, event ? event.target.name : null, this.state.step);

		if (event && (event.target.name === 'password_re' || typeof event.target.name === 'undefined')) {
			if (this.state.password.value !== this.state.password_re.value) {
				shouldContinue = false;
				API.Validator.constructor.process_error(this, 'password_re', 'Passwords must match');
			}
		}

		return shouldContinue;
	};

	handleNext = () => {
		let shouldContinue = API.Validator.process(this, this.requestType, true, null, this.state.step);
		if (shouldContinue) {
			this.setState({"step": this.state.step + 1});
		}
	};
	handlePrevious = () => {
		//let shouldContinue = API.Validator.process(this, this.requestType, true, null, this.state.step);
		//if( shouldContinue ) {
		this.setState({"step": this.state.step - 1});
		//}
	};

	handleSubmit = async event => {
		if (typeof event !== 'undefined') event.preventDefault();
		this.setState({loading: true});
		if (this.handleProcess()) {

			let {token} = await this.props.stripe.createToken({name: "Name"});
			this.state.token.value = token.id;

			this.model.submit(this, this.state.pkey && this.state.pkey.value ? API.RequestType.UPDATE : API.RequestType.CREATE, () => {
				this.props.history.push("/skus");
			}).then();
		} else {
			if (typeof this.props.attemptedSave === "function") {
				this.props.attemptedSave();
			}
			this.setState({loading: false});
		}
		return false;
	};

	render() {

		let states = this.state.states.map(function (state, i) {
			/**
			 * @object state
			 * @property abbreviation
			 */
			return <MenuItem key={"state" + (i + 1)} value={state.abbreviation}>{state.name}</MenuItem>
		});
		let processors = this.state.processors.map(function (processor, i) {
			return <MenuItem key={"state" + (i + 1)} value={processor.pkey}>{processor.name}</MenuItem>
		});

		let price = this.state.quantity.value < 1 ? 0 :
			(((this.state.quantity.value - 1) * 50) + 75)
		;

		/**
		 * @object this.state
		 * @property address_1
		 * @property address_1.value
		 * @property address_1.error
		 * @property address_2
		 * @property address_2.value
		 * @property address_2.error
		 * @property tax_rate_state
		 * @property tax_rate_county
		 * @property tax_rate_city
		 * @property tax_rate_special
		 * @property reference_id
		 * @property processor
		 * @property processor.value
		 * @property processor.error
		 */

		let brand = this.brand;
		let step = this.state.step;
		return (
			<div className="body-inner">

				<section className="logo text-center">
					<h1><a href="/">{brand}</a></h1>
				</section>
				<div className="card bg-white">

					{step === 1 ?
						<div>
							<div className="card-content">
								<h4 style={{textAlign: "center", margin: "0 0 10px"}}>Account Setup</h4>
								<fieldset>
									<div className={"row"}>
										<div className={"col-md"}>
											<div className="form-group">
												<TextField
													defaultValue={this.state.name_first.value}
													error={this.state.name_first.error !== ""}
													helperText={this.state.name_first.error}
													inputProps={{name: 'name_first'}}
													onChange={this.handleDataChange}
													onBlur={this.handleProcess}
													label="First Name"
													fullWidth={true}
													margin="normal"
												/>
											</div>
										</div>
										<div className={"col-md"}>
											<div className="form-group">
												<TextField
													defaultValue={this.state.name_last.value}
													error={this.state.name_last.error !== ""}
													helperText={this.state.name_last.error}
													inputProps={{name: 'name_last'}}
													onChange={this.handleDataChange}
													onBlur={this.handleProcess}
													label="Last Name"
													fullWidth={true}
													margin="normal"
												/>
											</div>
										</div>
									</div>
									<div className="form-group">
										<TextField
											defaultValue={this.state.email.value}
											error={this.state.email.error !== ""}
											helperText={this.state.email.error}
											inputProps={{name: 'email'}}
											onChange={this.handleDataChange}
											onBlur={this.handleProcess}
											label="Email"
											fullWidth={true}
											margin="normal"
										/>
									</div>
									<div className="form-group">
										<TextField
											defaultValue={this.state.password.value}
											error={this.state.password.error !== ""}
											helperText={this.state.password.error}
											inputProps={{name: 'password'}}
											onChange={this.handleDataChange}
											onBlur={this.handleProcess}
											label="Password"
											type="password"
											fullWidth={true}
											margin="normal"
										/>
									</div>
									<div className="form-group">
										<TextField
											defaultValue={this.state.password_re.value}
											error={this.state.password_re.error !== ""}
											helperText={this.state.password_re.error}
											inputProps={{name: 'password_re'}}
											onChange={this.handleDataChange}
											onBlur={this.handleProcess}
											label="Confirm Password"
											type="password"
											fullWidth={true}
											margin="normal"
										/>
									</div>
								</fieldset>
							</div>
							<div className="card-action no-border text-right">
								<Link to={"/login"}>
									<Button color="secondary" size={"md"} block={false}>Back to Login</Button>
								</Link>
								<a href="#" onClick={this.handleNext}>
									<Button color="primary" size={"md"} block={false}>Next</Button>
								</a>
							</div>
						</div> : null}

					{step === 2 ?
						<div>
							<div className="card-content">
								<h4 style={{textAlign: "center", margin: "0 0 10px"}}>Store Information</h4>
								<fieldset>
									<div className="form-group">
										<TextField
											defaultValue={this.state.name["value"]}
											error={this.state.name["error"] !== ""}
											helperText={this.state.name["error"]}
											inputProps={{name: 'name'}}
											onChange={this.handleDataChange}
											onBlur={this.handleProcess}
											label="Business Name"
											fullWidth={true}
											margin="normal"
										/>
									</div>
									<div className={"row"}>
										<div className={"col-md-7"}>
											<div className="form-group">
												<TextField
													defaultValue={this.state.address_1.value}
													error={this.state.address_1.error !== ""}
													helperText={this.state.address_1.error}
													inputProps={{name: 'address_1'}}
													onChange={this.handleDataChange}
													onBlur={this.handleProcess}
													label="Address 1"
													fullWidth={true}
													margin="normal"
												/>
											</div>
										</div>
										<div className={"col-md-5"}>
											<div className="form-group">
												<TextField
													defaultValue={this.state.address_2.value}
													error={this.state.address_2.error !== ""}
													helperText={this.state.address_2.error}
													inputProps={{name: 'address_2'}}
													onChange={this.handleDataChange}
													onBlur={this.handleProcess}
													label="Address 2"
													fullWidth={true}
													margin="normal"
												/>
											</div>
										</div>
									</div>
									<div className={"row"}>
										<div className={"col-md-5"}>
											<div className="form-group">
												<TextField
													value={this.state.city.value}
													error={this.state.city.error !== ""}
													helperText={this.state.city.error}
													inputProps={{name: 'city'}}
													onChange={this.handleDataChange}
													onBlur={this.handleProcess}
													label="City"
													fullWidth={true}
													margin="normal"
												/>
											</div>
										</div>
										<div className={"col-md-4"}>
											<div className="form-group">
												<FormControl fullWidth={true}
												             error={this.state.state.error !== ""}
												             style={{marginTop: '16px'}}>
													<InputLabel htmlFor="state">State</InputLabel>
													<Select
														value={this.state.state.value}
														inputProps={{name: 'state'}}
														onChange={this.handleDataChange}
														onBlur={this.handleProcess}
														label="State"
														fullWidth={true}
													>
														{states}
													</Select>
												</FormControl>
											</div>
										</div>
										<div className={"col-md-3"}>
											<div className="form-group">
												<TextField
													value={this.state.zip.value}
													error={this.state.zip.error !== ""}
													helperText={this.state.zip.error}
													inputProps={{name: 'zip'}}
													onChange={this.handleDataChange}
													onBlur={this.handleProcess}
													label="Zip Code"
													fullWidth={true}
													margin="normal"
												/>
											</div>
										</div>
									</div>

									<hr/>

									<h4 style={{textAlign: "center", margin: "0 0 10px"}}>Sales Tax Rates</h4>
									<div className={"row"}>
										<div className={"col-md"}>
											<div className="form-group">
												<TextField
													value={this.state.tax_rate_state.value}
													error={this.state.tax_rate_state.error !== ""}
													helperText={this.state.tax_rate_state.error}
													onChange={this.handleDataChange}
													onBlur={this.handleProcess}
													inputProps={{name: 'tax_rate_state'}}
													label="State"
													fullWidth={true}
													margin="normal"
													type={"number"}
													InputProps={{
														startAdornment: (
															<i className="fa fa-percent" style={{marginRight: '5px'}}/>
														)
													}}
												/>
											</div>
										</div>
										<div className={"col-md"}>
											<div className="form-group">
												<TextField
													value={this.state.tax_rate_county.value}
													error={this.state.tax_rate_county.error !== ""}
													helperText={this.state.tax_rate_county.error}
													onChange={this.handleDataChange}
													onBlur={this.handleProcess}
													inputProps={{name: 'tax_rate_county'}}
													label="County"
													fullWidth={true}
													margin="normal"
													type={"number"}
													InputProps={{
														startAdornment: (
															<i className="fa fa-percent" style={{marginRight: '5px'}}/>
														)
													}}
												/>
											</div>
										</div>
										<div className={"col-md"}>
											<div className="form-group">
												<TextField
													value={this.state.tax_rate_city.value}
													error={this.state.tax_rate_city.error !== ""}
													helperText={this.state.tax_rate_city.error}
													onChange={this.handleDataChange}
													onBlur={this.handleProcess}
													inputProps={{name: 'tax_rate_city'}}
													label="City"
													fullWidth={true}
													margin="normal"
													type={"number"}
													InputProps={{
														startAdornment: (
															<i className="fa fa-percent" style={{marginRight: '5px'}}/>
														)
													}}
												/>
											</div>
										</div>
										<div className={"col-md"}>
											<div className="form-group">
												<TextField
													value={this.state.tax_rate_special.value}
													error={this.state.tax_rate_special.error !== ""}
													helperText={this.state.tax_rate_special.error}
													onChange={this.handleDataChange}
													onBlur={this.handleProcess}
													inputProps={{name: 'tax_rate_special'}}
													label="Other/Special"
													fullWidth={true}
													margin="normal"
													type={"number"}
													InputProps={{
														startAdornment: (
															<i className="fa fa-percent" style={{marginRight: '5px'}}/>
														)
													}}
												/>
											</div>
										</div>
									</div>

								</fieldset>
							</div>
							<div className="card-action no-border text-right">
								<a href="#" onClick={this.handlePrevious}>
									<Button color="secondary" size={"md"} block={false}>Back</Button>
								</a>
								<a href="#" onClick={this.handleNext}>
									<Button color="primary" size={"md"} block={false}>Next</Button>
								</a>
							</div>
						</div> : null}

					{step === 3 ?
						<div>
							<div className="card-content">
								<fieldset>
									<h4 style={{textAlign: "center", margin: "0 0 10px"}}>Processor Connection</h4>
									<div className={"row"}>
										<div className={"col-md-4"}>
											<div className="form-group">
												<FormControl fullWidth={true}
												             error={this.state.processor.error !== ""}
												             style={{marginTop: '16px'}}>
													<InputLabel htmlFor="state">Processor</InputLabel>
													<Select
														value={this.state.processor.value}
														inputProps={{name: 'processor'}}
														onChange={this.handleDataChange}
														onBlur={this.handleProcess}
														label="Processor"
														fullWidth={true}
													>
														{processors}
													</Select>
												</FormControl>
											</div>
										</div>
										<div className={"col-md-6"}>
											<div className="form-group">
												<TextField
													value={this.state.reference_id.value}
													error={this.state.reference_id.error !== ""}
													helperText={this.state.reference_id.error}
													inputProps={{name: 'reference_id'}}
													onChange={this.handleDataChange}
													onBlur={this.handleProcess}
													label="API Key"
													fullWidth={true}
													margin="normal"
												/>
											</div>
										</div>
										<div className={"col-md-2"}>
											<div className="form-group">
												<TextField
													value={this.state.quantity.value}
													error={this.state.quantity.error !== ""}
													helperText={this.state.quantity.error}
													inputProps={{name: 'quantity'}}
													onChange={this.handleDataChange}
													onBlur={this.handleProcess}
													label="Terminals"
													fullWidth={true}
													margin="normal"
													type={"number"}
												/>
											</div>
										</div>
									</div>
									<hr/>
									<h4 style={{textAlign: "center", margin: "0 0 10px"}}>Your cost is
										${price}/mo</h4>
									<p style={{textAlign: "center", fontSize: "12px"}}>
										<b>$75/mo</b> for 1 Terminal connection<br/>
										<b>$50/mo</b> for each additional Terminal connection
									</p>

									<div style={{
										margin: "20px 50px 0",
										backgroundColor: "#f9f9f9",
										padding: "10px"
									}}>
										<CardElement/>
									</div>

								</fieldset>
								<div className="divider"/>
								<div className="form-group">
									<p className="text-small text-center">By clicking on sign up, you agree
										to <a
											href="#"><i>terms</i></a> and <a href="#"><i>privacy policy</i></a>
									</p>
								</div>
							</div>
							<div className="card-action no-border text-right">
								<a href="#" onClick={this.handlePrevious}>
									<Button color="secondary" size={"md"} block={false}>Back</Button>
								</a>
								<a href="#" onClick={this.handleSubmit}>
									<Button color="primary" size={"md"} block={false}>Sign Up</Button>
								</a>
							</div>
						</div> : null}

				</div>

			</div>
		);
	}
}

const RegisterRoute = withRouter(injectStripe(Register));

const Page = () => (
	<div className="page-signup">
		<div className="main-body">
			<div key="1">
				<StripeProvider
					apiKey={APPCONFIG.settings.isDev() ? "pk_test_tRSfBrW6ZVEDUfzR5Z9LS0Dc" : "pk_live_5xbn5wfaZ994xK2auzKck9dp"}>
					<Elements>
						<RegisterRoute/>
					</Elements>
				</StripeProvider>
			</div>
		</div>
	</div>
);

export default Page;
