
import React from 'react';
import { render } from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import {createBrowserHistory, createHashHistory} from 'history';
import { TrackJS } from 'trackjs';
import APPCONFIG from 'constants/Config';
import isElectron from 'is-electron';
import 'babel-polyfill';

import App from './containers/App';
import Framework from '@beardeddevops/react.framework';
import {defaultState} from 'constants/State';

import ErrorBoundaryRoute from "./containers/ErrorBoundaryRoute";
const history = isElectron() ? createHashHistory() : createBrowserHistory();

if( !APPCONFIG.settings.isDev() ) {
	TrackJS.install({
		token: "197cc3fbd1be4e3cb724189bb24d4483",
		application: "best-pos",
		version: "1"
		// for more configuration options, see https://docs.trackjs.com
	});
}

render(
	<Framework.Components.RootState defaultState={defaultState}>
		<Router history={history}>
			<ErrorBoundaryRoute path={'/'}>
				<App/>
			</ErrorBoundaryRoute>
		</Router>
	</Framework.Components.RootState>
	,
	document.getElementById('app-container')
);
