import React from "react";
import {Link} from "react-router-dom";
import {Objects, Types} from "components";

import Framework from "@beardeddevops/react.framework";
import API from "@beardeddevops/react.api";

// noinspection JSUnresolvedVariable
/**
 * @param {Transactions_Entries_Object} entry
 * @param {Products_Object} product
 * @param {function} onDelete
 * @param {function} onUpdate
 * @param {function} openModal
 * @param {function} reportClose
 * @param {bool} modalUpdate
 * @param {object} history
 * @param {array} locations
 * @param {array} states
 * @param {function} updatePawn
 * @param {function} updateMagazine
 */
export default ({
	                entry,
	                product,
	                onDelete,
	                onUpdate,
	                modalUpdate,
	                openModal,
	                reportClose,
	                history,
                }) => {
	return (
		<div key={product.pkey} className="portlet box blue-hoki">
			<div className="portlet-title">
				<div className="caption">
					<i className="fa fa-barcode"/>&nbsp;&nbsp;
					{product.serial_number}
				</div>
				{onDelete && <div className="tools">
					<a href='#'
					   onClick={() => onDelete(entry.pkey || null, product,)}
					   className="remove"
					   style={{color: 'white'}}><i className={"fa fa-remove"}/></a>
				</div>}
			</div>
			<div className="portlet-body">
				{/*<Link to={"/products/" + product.pkey} className="btn btn-sm blue-hoki pull-right">View</Link>*/}
				<a onClick={openModal} className="btn btn-sm blue-hoki pull-right">View</a>
				<h5 style={{marginTop: 0}}>{product.type}</h5>
				<h5
					style={{margin: 0}}>{product.manufacturers ? product.manufacturers.name : product.manufacturer_other} | {product.model} | {product.calibers ? product.calibers.name : product.caliber_other}</h5>

			</div>

			<Framework.Elements.UniversalModal
				history={history}
				name={"Inventory"}
				id={product.pkey}
				object={Objects.Inventory}
				toggle={modalUpdate}
				reportClose={reportClose}
				afterSave={onUpdate}
				size={"xl"}
			/>
		</div>
	)
};
