import React from 'react';
import ReactToPrint from 'react-to-print';

const style = `
	@page{
		size: 2in 2.5in portrait;
		padding: 140mm;
		margin: 0mm;
		font-family: Arial,	sans-serif;
	}

	@media print {

		body {
			-webkit-print-color-adjust: exact;
			font-size: 16px;
			font-family: Arial,	sans-serif;
			margin: .5rem;
			padding: .5rem;
		}
		#printItems {
		padding-left: .25rem;
		padding-right: .25rem;
		}

		#printPrice,#printRefunded,#printQuantity {
			float:right;
		}

		#poInfo > div{
		padding-bottom: 10px;
		}

		li {
		list-style-type:none;
		text-align:right;
		}

		#hrPrint {
		color: black;
		background-color: white;
		margin-left: 50%;
}
		#invoiceContainer{
		padding:2rem;
		}

		#invoiceTable {
		font-size: 16px;
		border-collapse: collapse;
		}

		#invoiceRow:nth-child(even) {background-color: #f2f2f2;}

		#invoiceTitle {
		color : black;
		font-weight: bold;
		}

		#centerInvoice {
		text-align: center;
		padding: 10px;
		margin: 1rem;
}
}

	}`;

// Wrapper around ReactToPrint that works around its pattern of passing anonymous functions in the render method
export default class Print extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			contentRef: null,
			triggerElement: () => this.props.trigger,
		}
	}

	getContentRef = (ref) => {
		if (!ref) return;

		this.setState({
			contentRef: () => ref
		})
	}



	render() {

		const {content, trigger, ...rest} = this.props;
		const {contentRef, triggerElement} = this.state;

		if (!contentRef) {
			return (
				<div ref={this.getContentRef}>
					{content}
				</div>
			)
		}

		return (
			<ReactToPrint
				content={contentRef}
				trigger={triggerElement}
				pageStyle={style}
				{...rest}
			/>
		);
	}
}

export class PrintReceiptModal extends React.Component {
	render() {
		const {onClose, receiptData} = this.props;
		return (
			<div className="react-confirm-alert" style={{overflowY: "auto", height: "95vh", backgroundColor: "white"}}>
				<div className="react-confirm-alert-body">
					{receiptData}
					<div className="react-confirm-alert-button-group">
						<div style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
							<Print
								trigger={

									<button style={{left: '0px', marginRight: 'auto', marginLeft: "7.5rem"}} className={'btn btn-primary'}>
										Print Receipt
									</button>
								}
								content={receiptData}
								onAfterPrint={onClose}
								copyStyles={false}

							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
