import React from "react";
import {withRouter} from "react-router";
import classNames from "classnames";

import ClipLoader from 'react-spinners/ClipLoader';
import Typography from '@material-ui/core/Typography';

import API from "@beardeddevops/react.api";
import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';

class SettingsObject extends Framework.Components.Object {

	constructor(props) {
		/**
		 *  @typedef {Accounts_Subscriptions_Object} this.state    Skus
		 */
		super(props, Types.Accounts._name);
		this.noDelete = false;
	}

	handleSubmit(event) {
		if (typeof event !== 'undefined') event.preventDefault();
		this.setState({loading: true});
		if (this.handleProcess()) {
			this.model.submit(this, API.RequestType.UPDATE, false).then(() => {
				this.props.attemptedSave(true);
				this.setState({loading: false});

				this.setState({
					password: '',
					password_temp: '',
					password_re: '',
					password_new: ''
				});
			});
		} else {
			this.setState({loading: false});
			this.props.attemptedSave(false);
		}
		return false;
	}

	redirect = "/settings";
	// noinspection JSUnusedGlobalSymbols
	account_level = true;

	loadSelfActual(data) {
		super.loadSelfActual(data);
		this.setState({password: ''});
	}

	form = () => {
		let {properties} = this.model;
		let {
			name_first,
			name_first_error,
			name_last,
			name_last_error,
			email,
			email_error,
			password_temp,
			password_temp_error,
			password_re,
			password_re_error,
			password,
			password_error,
			password_new,
			password_new_error
		} = this.state;

		return (

			<div>
				<div>
					<Typography variant="h5" component="h2">
						{name_first} {name_last}
					</Typography>
					<hr/>
				</div>
				<form>
					<fieldset>
						<div className="form-group">
							<Framework.Elements.TextField name={"name_first"}
							                              label={"First Name"}
							                              property={properties.name_first}
							                              value={name_first}
							                              error={name_first_error}
							                              update={this.handleTextFieldPropertyUpdate}
							/>
						</div>
						<div className="form-group">
							<Framework.Elements.TextField name={"name_last"}
							                              label={"Last Name"}
							                              property={properties.name_last}
							                              value={name_last}
							                              error={name_last_error}
							                              update={this.handleTextFieldPropertyUpdate}
							/>
						</div>
						<div className="form-group">
							<Framework.Elements.TextField name={"email"}
							           label={"Email"}
												 property={properties.email}
												 value={email}
												 error={email_error}
							           update={this.handleTextFieldPropertyUpdate}
							/>
						</div>
						<div className="form-group">
							<Framework.Elements.TextField name={"password_new"}
							           label={"New Password"}
												 property={properties.password_new}
												 value={password_new}
												 error={password_new_error}
							           update={this.handleTextFieldPropertyUpdate}
							/>
						</div>
						<div className="form-group">
							<Framework.Elements.TextField name={"password_re"}
							           label={"Retype Password"}
												 property={properties.password_re}
												 value={password_re}
												 error={password_re_error}
							           update={this.handleTextFieldPropertyUpdate}
							           onKeyPress={e => {
								           if (e.key === 'Enter') {
									           this.handleSubmit()
								           }
							           }}
							/>
						</div>
						<div className="form-group">
							<Framework.Elements.TextField name={"password"}
							           label={"Current or Temporary Password"}
												 property={properties.password}
												 value={password}
												 error={password_error}
							           update={this.handleTextFieldPropertyUpdate}
							           onKeyPress={e => {
								           if (e.key === 'Enter') {
									           this.handleSubmit()
								           }
							           }}
							/>
						</div>
					</fieldset>

				</form>


				<div className={classNames("loader", {'active': properties.loading})}>
					<ClipLoader color={"#4A4A4A"} loading={properties.loading}/>
				</div>
			</div>
		)
	}
}

export default withRouter(SettingsObject);
