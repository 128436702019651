import React from "react";

import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';
import {Tooltip} from "@material-ui/core";
import {Button} from "reactstrap";
import {Collections, Objects, Elements} from "components";

class AddLayaway extends Framework.Components.Object {
	constructor(props) {
		/**
		 *  @typedef {Layaways_Object} this.state
		 */
		super(props, Types.Layaways._name);
		this.state.items = [];
		this.state.products = [];
		this.state.sub_total_amount = 0.00;
		this.state.tax_amount = 0.00;
		this.state.amount = 0.00;
		this.state.deposit = 0.00;
		this.state.remaining = 0.00;

	}
	addItems = async (items) => {

		let total = parseFloat(this.state.sub_total_amount);
		let entries = this.state.items
		if (typeof this.state.items !== 'undefined' && Array.isArray(items)) {
			items.forEach(item =>{
				let found = entries.find((entry)=>entry.item === item.pkey)
				if(!found){
					entries.push({item:item.pkey,items:item})
				}else{
					let index = entries.findIndex((entry)=>entry.item === item.pkey)
					entries[index].items.quantity += item.quantity
				}
				total += parseFloat(item.skus.price_retail * item.quantity)
			})
			// let entries = [...this.state.items, ...items.map(
			// 	item => {
			// 		total += parseFloat(item.skus.price_retail * item.quantity);
			// 		return {
			// 			item: item.pkey,
			// 			items: item
			// 		}
			// 	}
			// )];
			this.setState({items: entries, sub_total_amount: total});

		}
	}
	addProducts = async (products) => {
		let total = parseFloat(this.state.sub_total_amount);
		if (typeof this.state.products !== 'undefined' && Array.isArray(products)) {
			let entries = [...this.state.products, ...products.map(
				product => {
					total += parseFloat(product.price_list);
					return {
						product: product.pkey,
						products: product
					}
				}
			)];
			this.setState({products: entries, sub_total_amount: total});
		}
	}
	deleteItem = (entry, item) => {
		let total = parseFloat(this.state.sub_total_amount);
		if (!entry) {
			if (typeof this.state.items !== 'undefined') {
				let entries = this.state.items.filter(entry => entry.item !== item.pkey);
				total -= parseFloat(item.skus.price_retail * item.quantity);
				this.setState({items: entries, sub_total_amount: total});
			}
		}
	}
	deleteProduct = (entry, product) => {
		let total = parseFloat(this.state.sub_total_amount);
		if (!entry) {
			if (typeof this.state.products !== 'undefined') {
				let entries = this.state.products.filter(entry => entry.product !== product.pkey);
				total -= parseFloat(product.price_list);
				this.setState({products: entries, sub_total_amount: total});
			}
		}
	}
	updateItem = (item, newEntry = null) => {
		if (typeof this.state.items !== 'undefined') {
			let entries = this.state.items.map(entry => {
				if (entry.item === item.pkey) {
					entry.item = item.pkey;
					entry.items = item;

				}
				return entry;
			});
			this.setState({items: entries});
		}
		if (!newEntry) {
			this.props.reloadPage();
		}
	}
	updateProduct = (product, newEntry = null) => {
		if (typeof this.state.products !== 'undefined') {
			let entries = this.state.products.map(entry => {
				if (entry.product === product.pkey) {
					entry.product = product.pkey;
					entry.products = product;

				}
				return entry;
			});
			this.setState({product: entries});
		}
		if (!newEntry) {
			this.props.reloadPage();
		}
	}
	handleToggleItemModel = (forItem) => {
		if (typeof this.state[forItem] === 'undefined') {
			this.state[forItem] = false;
		}
		return () => {
			this.setState({[forItem]: true});
		};
	}
	handleReportModalClose = (forItem) => () => {
		this.setState({[forItem]: false});
	}
	handleAddPayment = (payment) => {
		this.state.cash_amount = payment.cash_amount
		this.state.check_amount = payment.check_amount
		let deposit = parseFloat(payment.cash_amount) + parseFloat(payment.check_amount)
		this.setState({deposit: parseFloat(deposit)})
	}

	form = () => {
		/**
		 * @type {Layaways_Properties} properties
		 */
		let properties = this.model.properties;
		/**
		 *  @type {Layaways_Object} object
		 */
		let object = this.state;
		let {location} = this.props.rootState
		object.tax_rate = parseFloat(location.TaxRateCity) + parseFloat(location.TaxRateCounty) + parseFloat(location.TaxRateSpecial) + parseFloat(location.TaxRateState)

		object.tax_amount = Math.ceil(parseFloat(object.sub_total_amount) * (object.tax_rate / 100) * 100) / 100;
		object.amount = (Math.ceil((object.sub_total_amount + object.tax_amount) * 100) / 100);

		object.remaining = (parseFloat(object.amount - (object.deposit))).toFixed(2)
		return (
			<>
				<fieldset>
					<div className={'row'}>
						<div className={'col-lg-5'}>
							<h4 className="section-title">Layaway Details</h4>
							<div className={'row'}>
								<div className="col">
									<div className={"form-group"}>
										<Framework.Elements.TextField
											label={"Customer Name"}
											value={object.customer}
											error={object.customer_error}
											property={properties.customer}
											update={this.handleTextFieldPropertyUpdate}
										/>
									</div>
								</div>
							</div>
							<div className={'row'}>
								<div className="col">
									<div className={"form-group"}>
										<Framework.Elements.Date
											label={"Estimated Payoff Date"}
											value={object.estimated_date}
											error={object.estimated_date_error}
											property={properties.estimated_date}
											update={this.handleTextFieldPropertyUpdate}
										/>
									</div>
								</div>
							</div>
							<div className={'row'}>
								<div className="col">
									<div className={"form-group"}>
										<Framework.Elements.Select
											shouldLoad={this.state.loaded}
											object={object.registers}
											objects={object.all_registers}
											value={object.register}
											error={object.register_error}
											model={Types.Registers._name}
											property={properties.register}
											label={'Registers'}
											update={this.handleSelectPropertyUpdate}
										/>
									</div>
								</div>
							</div>
							<div className=" invoice-sum text-right">
								<ul className="list-unstyled">
									<li>Sub Total: $ {parseFloat(object.sub_total_amount).toFixed(2)}</li>
									<li>Tax ({object.tax_rate}%): $ {object.tax_amount.toFixed(2)}</li>
									<li><strong>Total:
										$ {isNaN(object.amount) ? "0.00" : parseFloat(object.amount).toFixed(2)}</strong>
									</li>
									<li>Deposit: $ {object.deposit.toFixed(2)}</li>
									<li><strong>Remaining: $ {object.remaining}</strong></li>
								</ul>
							</div>

							<div className={"row"}>
								<div className={"col-6"}>
									<Button onClick={this.toggle('modalPayments')} size={"block"} color="primary">
										<i className="fa fa-dollar"/>&nbsp;&nbsp; Manual Payment
									</Button>
								</div>
								<div className={"col-6"}>
									<Button size={"block"} color="primary">
										<i className="fa fa-dollar"/>&nbsp;&nbsp; Charge Card
									</Button>
								</div>
							</div>


						</div>
						<div className={'col-lg-7'}>
							<div className={'section-title'}>
								<h4 className="section-title">Layaway Items</h4>
								<div className={"button-container"}>
									<Tooltip title={"Add Best POS Products "}>
										<Button color={'primary'} size={'sm'}
										        onClick={this.toggle('modalLayawayItems')}>
											<i className={"fa fa-plus"}/>
											<span className="hidden-xs">&nbsp;Add Item</span>
										</Button>
									</Tooltip>
									<Tooltip style={{marginLeft: '0px'}} title={"Add FFL Boss Products "}>
										<Button color={'primary'} size={'sm'}
										        onClick={this.toggle('modalLayawayProducts')}>
											<i className={"fa fa-plus"}/>
											<span className="hidden-xs">&nbsp;Add Product</span>
										</Button>
									</Tooltip>
								</div>

							</div>
							<div style={{
								overflowY: "auto",
								paddingRight: "4px",
								maxHeight: "362px"
							}}>
								{object.items && object.items.map((entry, i) =>
									<Elements.Item
										key={i}
										entry={entry}
										product={entry.items}
										onDelete={this.deleteItem}
										onUpdate={this.updateItem}
										openModal={this.handleToggleItemModel('itemUpdate' + i)}
										reportClose={this.handleReportModalClose('itemUpdate' + i)}
										modalUpdate={this.state['itemUpdate' + i]}
										history={this.props.history}
									/>)}
							</div>
							<div style={{
								overflowY: "auto",
								paddingRight: "4px",
								maxHeight: "362px"
							}}>
								{object.products && object.products.map((entry, i) =>
									<Elements.Product
										key={i}
										entry={entry}
										product={entry.products}
										onDelete={this.deleteProduct}
										onUpdate={this.updateProduct}
										openModal={this.handleToggleItemModel('itemUpdate' + i)}
										reportClose={this.handleReportModalClose('itemUpdate' + i)}
										modalUpdate={this.state['itemUpdate' + i]}
										history={this.props.history}
									/>)}
								<p style={{textAlign: 'center', color: 'grey'}}><i>{object.items.length} Total Items
									& {object.products.length} Total Products </i></p>
							</div>
						</div>
					</div>
				</fieldset>
				<Framework.Elements.UniversalModal
					// isDev={APPCONFIG.settings.isDev()}
					locations={this.props.locations}
					name={"Layaway Items"}
					object={Collections.LayawayItems}
					history={this.props.history}
					toggle={this.state['modalLayawayItems']}
					reportClose={this.reportClose('modalLayawayItems')}
					afterSave={this.addItems}
					size={'xl'}
				/>
				<Framework.Elements.UniversalModal
					// isDev={APPCONFIG.settings.isDev()}
					locations={this.props.locations}
					name={"Layaway Products"}
					object={Collections.LayawayProducts}
					history={this.props.history}
					toggle={this.state['modalLayawayProducts']}
					reportClose={this.reportClose('modalLayawayProducts')}
					afterSave={this.addProducts}
					size={'xl'}
				/>
				<Framework.Elements.UniversalModal
					// isDev={APPCONFIG.settings.isDev()}
					locations={this.props.locations}
					name={"Layaway Payment"}
					object={Objects.AddLayawayPayment}
					history={this.props.history}
					toggle={this.state['modalPayments']}
					reportClose={this.reportClose('modalPayments')}
					afterSave={this.addProducts}
					size={'lg'}
					objectProps={{
						handleAddPayment: this.handleAddPayment,
						total: this.state.amount,
					}}
				/>
			</>
		)
	}
}

export default Framework.Components.withRootState(AddLayaway);
