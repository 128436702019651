import React from "react";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';

import Framework from "@beardeddevops/react.framework";
import Types from "constants/Types";
import {Button} from "reactstrap";


/**
 *  @property {Certificates_Object} state
 *  @property {object} model
 *  @property {Certificates_Properties} model.properties
 *  @property {Certificates_Is} model.is
 *  @property {Certificates_Has} model.has
 */

export default class Payment extends Framework.Components.Object {
	constructor(props) {
		super(props, Types.Layaways_Payments._name);
		this.state.focused = "";
		this.state.manual = false;

	}

	componentDidMount() {
		this.state.cash_amount = "0.00";
		this.state.check_amount = "0.00";
	}

	handleAmountFocus = (event) => {
		if (this.state[event.currentTarget.name] === "0.00") this.setState({[event.currentTarget.name]: this.state.remaining});
	};

	form = () => {
		/**
		 * @type {Layaways_Payments_Properties} properties
		 */
		let properties = this.model.properties;
		/**
		 *  @type {Layaways_Payments_Object} object
		 */
		let object = this.state;
		let remaining = this.state.amount || (0.00).toFixed(2)
		if (!isNaN(object.cash_amount) && object.cash_amount) remaining -= parseFloat(object.cash_amount)
		if (!isNaN(object.check_amount) && object.check_amount) remaining -= parseFloat(object.check_amount)
		remaining = Math.ceil(remaining * 100) / 100;
		this.state.remaining = remaining.toFixed(2);

		return (
			<div>

				<fieldset>
					<h4 className="section-title">Details</h4>
					<div className={"row"}>
						<div className={"col"}>
							<div className={"form-group"}>
								<Framework.Elements.Select
									shouldLoad={this.state.loaded}
									object={object.registers}
									value={object.register}
									error={object.register_error}
									model={Types.Registers._name}
									property={properties.register}
									label={'Registers'}
									update={this.handleSelectPropertyUpdate}
								/>
							</div>
						</div>
						<div className={"col"}>
							<div className={'form-group'}>
								<Framework.Elements.DollarAmount
									name={"amount"}
									value={object.amount}
									error={object.amount_error}
									label={"Total Amount"}
									property={properties.amount}
									update={this.handleTextFieldPropertyUpdate}
									margin={"dense"}
								/>
							</div>
						</div>
					</div>
					<div className={'row'}>
						<div className={'col'}>
							<div className={"form-group"}>
								<Framework.Elements.TextField
									name={"description"}
									label={"Description"}
									value={object.description}
									error={object.description_error}
									property={properties.description}
									update={this.handleTextFieldPropertyUpdate}
									multiline={true}
								/>
							</div>
						</div>
					</div>
					<div className={"row form-group"}>

						<div className={'col'}>
							<Button onClick={()=>{this.setState({manual:!this.state.manual})}} size={"block"} color="primary">
								<i className="fa fa-dollar"/>&nbsp;&nbsp; Manual Payment
							</Button>
						</div>
						<div className={'col'}>
							<Button size={"block"} color="primary">
								<i className="fa fa-dollar"/>&nbsp;&nbsp; Charge Card
							</Button>
						</div>

					</div>

					{object.manual?
						<div className="row">
						<div className="col-6">

							<h4 className="section-title">Manual Credit Card Entry </h4>
							<div className="row">

								{/*<Cards*/}
								{/*	// number={object.credit_card_number}*/}
								{/*	name={object.credit_card_name}*/}
								{/*	expiry={object.credit_card_expiry}*/}
								{/*	cvc={object.credit_card_cvc}*/}
								{/*	focused={object.focused}*/}
								{/*/>*/}
								<div className={'col'}>

									<div className={"form-group"}>
										<Framework.Elements.TextField
											name={"credit_card_number"}
											label={"Card Number"}
											value={object.credit_card_number}
											error={object.credit_card_number_error}
											property={properties.credit_card_number}
											update={this.handleTextFieldPropertyUpdate}
										/>
									</div>
									<div className={"form-group"}>
										<Framework.Elements.TextField
											name={"credit_card_name"}
											label={"Card Holder Name"}
											property={properties.credit_card_name}
											value={object.credit_card_name}
											error={object.credit_card_name_error}
											update={this.handleTextFieldPropertyUpdate}
										/>
									</div>
									<div className="row">
										<div className="col">
											<div className={"form-group"}>
												<Framework.Elements.TextField
													name={"credit_card_expiry"}
													label={"Exp."}
													value={object.credit_card_expiry}
													error={object.credit_card_expiry_error}
													property={properties.credit_card_expiry}
													update={this.handleTextFieldPropertyUpdate}
												/>
											</div>
										</div>
										<div className="col form-group">
											<div className={"form-group"}>
												<Framework.Elements.TextField
													name={"credit_card_cvc"}
													label={"CVC"}
													value={object.credit_card_cvc}
													error={object.credit_card_cvc_error}
													property={properties.credit_card_cvc}
													update={this.handleTextFieldPropertyUpdate}
												/>
											</div>
										</div>
										<div className="col">
											<div className={"form-group"}>
												<Button size="md" block={true} color="primary"
												        onClick={() => this.checkout("POST_CARD")}>
													Process
												</Button>
											</div>
										</div>
									</div>
									<p style={{textAlign: 'center'}}>
										Click <strong>Process</strong> to run the credit card manually
										or
										click <strong>Charge Card</strong> to process the remaining
										balance
										on the Credit Card Terminal.
									</p>
								</div>

							</div>
						</div>
						<section className={'col-6'}>
							<h4 className="section-title"> Payments</h4>
							<div className={'row'}>
								<div className={'col'}>
									<div className={'form-group'}>
										<Framework.Elements.DollarAmount
											name={"cash_amount"}
											value={object.cash_amount}
											error={object.cash_amount_error}
											label={"Cash"}
											property={properties.cash_amount}
											update={this.handleTextFieldPropertyUpdate}
											handleFocus={this.handleAmountFocus}
											margin={"dense"}
										/>
									</div>
								</div>
							</div>
							<div className={'row'}>
								<div className={'col '}>
									<div className={'form-group'}>
										<Framework.Elements.DollarAmount
											name={"check_amount"}
											label={"Check"}
											property={properties.check_amount}
											value={object.check_amount}
											error={object.check_amount_error}
											update={this.handleTextFieldPropertyUpdate}
											handleFocus={this.handleAmountFocus}
											margin={"dense"}
										/>
									</div>
								</div>
							</div>
						</section>
					</div>:null}

	</fieldset>


		<div className={classNames("loader", {'active': this.state.loading})}>
			<ClipLoader color={"#4A4A4A"} loading={this.state.loading}/>
		</div>
	</div>
	)
	}
}
