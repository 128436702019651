import React from 'react';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';

import MailIcon from '@material-ui/icons/Mail';
import PropTypes from "prop-types";

class NavLeftList extends React.Component {
	static propTypes = {
		items: PropTypes.number
	};

	state = {
		anchorEl: null,
	};

	handleChange = (event, value) => {
		this.props.history.push(value);
	};

	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	render() {
		const { anchorEl } = this.state;

		return (
			<ul className="list-unstyled list-inline">
				<li className="list-inline-item">
					{/*<Button*/}
					{/*	className="md-button header-btn"*/}
					{/*	style={{height: '60px'}}*/}
					{/*>*/}
					{/*	<Link*/}
					{/*		to={"/checkout"}*/}
					{/*		style={{*/}
					{/*			lineHeight: '41px',*/}
					{/*			textDecoration: 'none'*/}
					{/*		}}*/}
					{/*	>*/}
					{/*		<Badge badgeContent={this.props.items} color="primary">*/}
					{/*			<i className="nav-icon material-icons">shopping_cart</i>*/}
					{/*		</Badge>*/}
					{/*		<span style={{ marginLeft: '10px' }}>Checkout</span>*/}
					{/*	</Link >*/}
					{/*</Button>*/}

					<Button
						aria-owns={anchorEl ? 'left-menu' : null}
						aria-haspopup="true"
						onClick={this.handleClick}
						className="md-button header-btn"
						style={{height: '60px', display: 'none'}}
					>
						<Badge badgeContent={3} color="primary">
							<i className="nav-icon material-icons">shopping_cart</i>
						</Badge>
						<span style={{
							lineHeight: '41px',
							marginLeft: '10px'
						}}>Checkout</span>
					</Button>
					<Menu
						id="left-menu"
						open={Boolean(anchorEl)}
						onClose={this.handleClose}
						anchorEl={anchorEl}
						onChange={this.handleChange}
						anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
						targetOrigin={{horizontal: 'left', vertical: 'top'}}
						menuStyle={{minWidth: '250px'}}
					>
						<MenuItem
							className="header-icon-dropdown-item"
							onClick={this.handleClose}
						>
							<ListItemIcon >
								<MailIcon />
							</ListItemIcon>
							<ListItemText primary={"New mail from XYZ"} secondary={"5min ago"} />
						</MenuItem>

					</Menu>
				</li>
			</ul>
		);
	}
}

export default withRouter(NavLeftList);
