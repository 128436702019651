import React from "react";
import {withRouter} from "react-router";
import Typography from '@material-ui/core/Typography';
import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';
import APPCONFIG from "../../constants/Config";
import {Button} from "reactstrap";
import API from "@beardeddevops/react.api";

class CustomReceiptObject extends Framework.Components.Object {

	constructor(props) {
		/**
		 * @typedef {Transactions_Printable_Customization_Object} this.state
		 */
		super(props, Types.Transactions_Printable_Customization._name);
		this.noDelete = false;
	}

	afterSubmit = () => {
		//reload the tab
		this.props.history.push('/');
		this.props.history.push('/settings#customizations');
	}

	//delete only the logo
	deleteLogo = () => {
		let modelLogo = new API.BuildClass(Types.Transactions_Printable_Customization._name, API.RequestType.DELETE);
		modelLogo.submit(null, 'DELETE_LOGOS').then( () => {
			//reload the tab
			this.props.history.push('/');
			this.props.history.push('/settings#customizations');
		});

	}

	form = ()  => {
		/** @type {Transactions_Printable_Customization_Properties} */
		let {properties} = this.model;
		/** @type {Transactions_Printable_Customization_Object} */
		let object = this.state;
		return(
			<div>
				<h4 className="section-title">Custom Receipt Options</h4>
				<form>
					<fieldset>
						<div className="form-group">
							<Framework.Elements.TextField
								name={"message"}
								label={"Custom Message"}
								property={properties.message}
								value={object.message ?? ""}
								error={object.message_error}
								multiline={6}
								update={this.handleTextFieldPropertyUpdate}
							/>
						</div>
						<div className="form-group">
							<Framework.Elements.TextField
								name={"warranty"}
								label={"Warranty Information"}
								property={properties.warranty}
								value={object.warranty}
								error={object.warranty_error}
								update={this.handleTextFieldPropertyUpdate}
							/>
						</div>
						<div className="form-group">
							<Framework.Elements.TextField
								name={"return_policy"}
								label={"Return Policy Information"}
								property={properties.return_policy}
								value={object.return_policy}
								error={object.return_policy_error}
								update={this.handleTextFieldPropertyUpdate}
							/>
						</div>

						{/*#region License Image Upload*/}
						<h4 className="section-title">Logo Upload</h4>

						<div className={"form-group"}>
							<p>
								Upload a copy of your logo.<br/>
								{object.logo ?
									<span>Current Logo - <b>{object.logo}</b><br/><i>Upload a new
										file to
										replace the old one.</i></span>
									: null}
							</p>
							<Framework.Elements.DropZone
								property={properties.logo}
								value={object.logo}
								error={object.logo_error}
								update={this.handleFileChange}
							/>
						</div>
						{object.logo && object.pkey &&
						<div>
							<div style={{textAlign: "center"}}>
								<img
									src={APPCONFIG.settings.apiURL() + '/' + APPCONFIG.settings.apiVersion + '/transactions/printable_customization/image'}
									alt={object.logo}
									style={{maxWidth: '100%', height: '300px'}}
								/>
							</div>
							<br></br>
							<div style={{textAlign: "-webkit-center"}}>
							<Button color="danger"
							        onClick={()=> this.deleteLogo()}
							        size={"md"} block={true}
									style={{width: "fit-content"}}>
								Delete Logo
							</Button>
							</div>
						</div>
							}

						{/*#endregion*/}
					</fieldset>
				</form>
			</div>
		)
	}
}

export default withRouter(CustomReceiptObject);
