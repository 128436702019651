import React from "react";

import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';
import API from "@beardeddevops/react.api";
// import moment from "moment";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';
import InputAdornment from "@material-ui/core/InputAdornment";

import {Parts} from "components";
import {MenuItem} from "@material-ui/core";

export default class Location extends Framework.Components.Object {
	constructor(props) {
		/**
		 *  @type {Locations_Object} this.state
		 */
		super(props, Types.Locations._name)
		this.state.parent = this.props.id;
		this.redirect = "/locations";
	}



	/*componentDidMount() {
		this.getStates().then()
	}*/

	/*getStates = async () => {
		let model = new API.BuildClass(Types.States._name, API.RequestType.READ_ALL);
		let states = await model.submit(null, 'GET_ALL')
		this.setState({states: states.items})
	}*/

	form = () => {

		/*let states = this.state.states?.map(function (state, i) {
			return <MenuItem key={"state" + (i + 1)} value={state.abbreviation}>{state.name}</MenuItem>
		});*/

		/**
		 * @param {Locations_Properties} data
		 */
		let {properties} = this.model;
		/**
		 *  @type {Locations_Object} object
		 */
		let object = this.state;


		return (
			<>
				<fieldset>
					<h4 className="section-title">Location</h4>
					<div className={"row"}>
						<div className={"col-md"}>
							<div className="form-group">
								<Framework.Elements.TextField
									name={"name"}
									label={"Name"}
									property={properties.name}
									value={object.name}
									error={object.name_error}
									update={this.handleTextFieldPropertyUpdate}
								/>
							</div>
							<div className="form-group">
								<Framework.Elements.TextField
									name={"store_number"}
									label={"Store Number"}
									value={object.store_number}
									error={object.store_number_error}
									property={properties.store_number}
									update={this.handleTextFieldPropertyUpdate}
								/>
							</div>
							<div className="form-group">
								<Framework.Elements.TextField
									name={"description"}
									label={"Description"}
									property={properties.description}
									value={object.description}
									error={object.description_error}
									update={this.handleTextFieldPropertyUpdate}
								/>
							</div>
							<div className="form-group">
								<Framework.Elements.PhoneNumber
									label={"Phone"}
									property={properties.phone}
									value={object.phone}
									error={object.phone_error}
									update={this.handleTextFieldPropertyUpdate}
								/>
							</div>
							<div className="form-group">
								<Framework.Elements.EmailAddress
									property={properties.email}
									value={object.email}
									error={object.email_error}
									update={this.handleTextFieldPropertyUpdate}
								/>
							</div>
						</div>
						<div className={"col-md"}>
							<Parts.Address
								object={object}
								properties={properties}
								onTextUpdate={this.handleTextFieldPropertyUpdate}
								onSelectUpdate={this.handleSelectPropertyUpdate}
								hasCounty={false}
							/>
						</div>
					</div>
					<hr/>
					<div className={"row"}>
						<div className={"col-md"}>
							<div className="form-group">
								<Framework.Elements.TextField
									name={"tax_rate_state"}
									label={"State Tax Rate"}
									property={properties.tax_rate_state}
									value={object.tax_rate_state}
									error={object.tax_rate_state_error}
									type={'string'}
									update={this.handleTextFieldPropertyUpdate}
									InputProps={{
										endAdornment: (
											<InputAdornment position={"end"}> <i
												className="fa fa-percent"/></InputAdornment>
										),
										shrink: false
									}}
								/>
							</div>
							<div className="form-group">
								<Framework.Elements.TextField
									name={"tax_rate_special"}
									label={"Other/Special Tax Rate"}
									property={properties.tax_rate_special}
									value={object.tax_rate_special}
									error={object.tax_rate_state_error}
									update={this.handleTextFieldPropertyUpdate}
									type={"string"}
									InputProps={{
										endAdornment: (
											<InputAdornment position={"end"}> <i
												className="fa fa-percent"/></InputAdornment>
										),
										shrink: false
									}}
								/>
							</div>
						</div>
						<div className={"col-md"}>
							<div className="form-group">
								<Framework.Elements.TextField
									name={"tax_rate_county"}
									label={"County Tax Rate"}
									property={properties.tax_rate_county}
									value={object.tax_rate_county}
									error={object.tax_rate_county_error}
									update={this.handleTextFieldPropertyUpdate}
									type={"string"}
									InputProps={{
										endAdornment: (
											<InputAdornment position={"end"}> <i
												className="fa fa-percent"/></InputAdornment>
										),
										shrink: false
									}}
								/>
							</div>
							<div className="form-group">
								<Framework.Elements.TextField

									label={"City Tax Rate"}
									property={properties.tax_rate_city}
									value={object.tax_rate_city}
									error={object.tax_rate_city_error}
									update={this.handleTextFieldPropertyUpdate}
									type={"string"}
									InputProps={{
										endAdornment: (
											<InputAdornment position={"end"}> <i
												className="fa fa-percent"/></InputAdornment>
										),
										shrink: false
									}}

								/>
							</div>
						</div>
					</div>
				</fieldset>
				<div className={classNames("loader", {'active': this.state.loading})}>
					<ClipLoader color={"#4A4A4A"} loading={this.state.loading}/>
				</div>
			</>
		)
	}

}
