import React from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";

import ReactTable from "react-table";
import {Button} from "reactstrap";
import ClipLoader from 'react-spinners/ClipLoader';
import {Card, CardContent} from "@material-ui/core";

import API from "@beardeddevops/react.api";
import APPCONFIG from "../../constants/Config";
import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';

import {toNumber} from "lodash";
import moment from "moment";


class LayawayEntries extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Layaways_Entries._name, API.RequestType.READ_ALL);
		//let transactionModel = new API.BuildClass(Types.Transactions)
		super(props, model);
		this.state.limit = 5;
		this.limit = 5;
	};

	render() {
		const {data, pages, loading,limit} = this.state;
		
		// let location = this.props.rootState.location;
		// let tax_rate = location == null ? 0 : parseFloat(location.TaxRateCity) + parseFloat(location.TaxRateCounty) + parseFloat(location.TaxRateSpecial) + parseFloat(location.TaxRateState);

		const columns = [
			{
				//Todo: Determine if we'll be able to add items, post Layaway creation. (all entries have the same date on creation).
				Header: "Date",
				id: "created",
				sortable: false,
				/** @param {Types.Layaways_Entries._name} obj */
				accessor: obj => {
					return obj.created ? moment(obj.created).local().format("MM/DD/YYYY HH:MM  A") : '-'
				}

			},

			{
				Header: "Serial",
				id: "serial_number",
				accessor: r => <a href={"https://app.fflboss.com/products/" + r.pkey} target={"_blank"}>
					{r.serial}
					<Framework.Printable.Label
						ref={el => (this['printable' + r.pkey] = el)}
						prefix="P" code={r.pkey} label={r.serial}/>
				</a>
			},
			//Todo: Fix column sorting - it's faulty
			{
				Header: "SKU",
				id: "sku",
				accessor: r => r.inventory
			},
			{
				Header: "Unit Price",
				id: "price",
				accessor: r => "$" + r.price
			},
			{
				Header: "Quantity",
				id: "quantity",
				accessor: r => r.quantity

			},

			{
				Header: "Total",
				id: "total",
				accessor: r => {

					return "$" + parseFloat(r.price * r.quantity).toFixed(2)
				}
			}
			,
		];
		return (<>
				<div className="table-bar">
					<div className="d-flex">
						{/*{this.renderSearch("Layaways")}*/}
						{this.renderHeaderRight("Layaways Items",columns)}
					</div>
				</div>
				<ReactTable
					key={this.state.key}
					columns={columns}
					manual // Forces table not to paginate or sort automatically, so we can handle it server-side
					data={data}
					pages={pages} // Display the total number of pages
					loading={loading} // Display the loading overlay when we need it
					loadingText="Requesting Data"
					onFetchData={this.fetchData} // Request new data when things change
					expanderDefaults={{
						sortable: true,
						resizable: true,
						filterable: false,
						width: undefined
					}}
					defaultSorted={[
						{
							id: this.model.sort,
							desc: this.model.order === 'desc'
						}
					]}
					showPagination= {true}
					showPaginationTop= {false}
					showPaginationBottom= {true}
					showPageSizeOptions= {true}
					defaultPageSize={limit}
					pageSize={limit}
					className="-striped -highlight"
				/>
				{APPCONFIG.settings.isDev() ? null :
					<Framework.Elements.LoadingSpinner isLoading={loading}/>
				}
		</>

		)
	}
}

export default Framework.Components.withRootState(LayawayEntries);
