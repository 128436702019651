import React from "react";
import {withRouter} from "react-router";
import classNames from "classnames";

import ClipLoader from 'react-spinners/ClipLoader';
import Typography from '@material-ui/core/Typography';

import API from "@beardeddevops/react.api";
import Framework from "@beardeddevops/react.framework";

import moment from "moment";
import Types from 'constants/Types';
import APPCONFIG from "../../constants/Config";
import {Objects} from "../index";
import {confirmAlert} from "react-confirm-alert";


class SubscriptionObject extends Framework.Components.Object {

	constructor(props) {
		/**
		 *  @typedef {Accounts_Subscriptions_Object} this.state
		 */
		super(props, Types.Accounts_Subscriptions._name);

	}

	loadSelfAfter(data) {
		this.loadBilling().then();
		this.loadTerimals().then();
	}

	loadTerimals = async () => {
		let terminals = new API.BuildClass(Types.Accounts_Subscriptions._name, API.RequestType.READ_ALL);
		terminals.url = "/api/terminals/check";
		let data = await terminals.submit(null, API.RequestType.READ);
		this.setState({quantity: data.quantity})
	}
	loadBilling = async () => {
		let billing = new API.BuildClass(Types.Accounts_Billing._name, API.RequestType.READ);
		let data = await billing.submit(null, API.RequestType.READ);
		let date = moment();
		date.set("year", data.exp_year)
		date.set("month", data.exp_month)
		this.setState({
			card_brand: data.brand,
			card_exp_date: data.exp_month + "/" + data.exp_year.toString().slice(2, 4),
			card_last4: data.last4
		})
	}


	render() {
		let {properties} = this.model;
		let {id, current_period_end, quantity, loading, plan} = this.state;
		let exists = !!(id || plan);
		let price = quantity < 1 ? 0 : (((quantity - 1) * 50) + 75);

		return (
			<div>
				<Typography variant="h5" component="h3">
					Subscription
				</Typography>

				{this.state.card_brand && <div className={"text-center"}>
					Current Card is a {this.state.card_brand} ending in {this.state.card_last4} Expires
					on {this.state.card_exp_date}
				</div>}
				<hr/>
				{exists ? <>
						<div className={"row"} style={{display: 'none'}}>
							<div className={"col-md-5"}/>
							<div className={"col-md-2"}>
								<div className="form-group">
									<Framework.Elements.TextField
										name={"quantity"}
										label="Terminals"
										property={properties.quantity}
										value={quantity}
										update={(p) => this.setState({quantity: p})}
									/>

								</div>
							</div>
							<div className={"col-md-5"}/>
						</div>
						<p style={{textAlign: "center", margin: "0 0 0 10px"}}>You have {quantity} active
							terminal(s) *</p>
						<h4 style={{textAlign: "center", margin: "0 0 10px"}}>Your cost is ${price}/mo</h4>
					</> :
					<h4 style={{textAlign: "center", margin: "0 0 10px"}}>You have no active subscriptions. Please subscribe below, contact support if you have questions
						or concerns.</h4>}
				{exists ? <div style={{textAlign: "center", fontSize: "12px"}}>Renews
					on {moment.unix(current_period_end).format("MM/DD/YYYY")}<br/><br/></div> : null}
				<p style={{textAlign: "center", fontSize: "12px"}}>
					<b>$75/mo</b> for 1 Terminal connection<br/>
					<b>$50/mo</b> for each additional Terminal connection
				</p>
				{exists ? <p style={{textAlign: "center", fontSize: "12px"}}>
					<strong>* Your Subscription will automatically adjust to the number of<br/>Dejavoo z11s that are
						active with your payment processor.</strong>
				</p> : null}

				<div className={classNames("loader", {'active': loading})}>
					<ClipLoader color={"#4A4A4A"} loading={loading}/>
				</div>

			</div>
		)
	}
}

export default Framework.Components.withRootState(SubscriptionObject);
