import CollectionsObject from "components/Collections";
import ElementsObject from "components/Elements"
import ObjectsObject from "components/Objects";
import BreadcrumbObject from "components/Breadcrumb";
import TypesObject from "constants/Types";
import PartsObject from 'components/Parts';

export let Collections = CollectionsObject;
export let Elements = ElementsObject
export let Objects = ObjectsObject;
export let Breadcrumb = BreadcrumbObject;
export let Types = TypesObject;
export let Parts = PartsObject;

const Components =  {
	Collections: Collections,
	Elements:Elements,
	Objects: Objects,
	Parts: Parts,
	Breadcrumb: Breadcrumb,
	Types: Types,
};

export default Components;
