import React from "react";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';

import Framework from "@beardeddevops/react.framework";
import Types from "constants/Types";
import moment from "moment";
import Cards from "react-credit-cards";
import {Button} from "reactstrap";


/**
 *  @property {Certificates_Object} state
 *  @property {object} model
 *  @property {Certificates_Properties} model.properties
 *  @property {Certificates_Is} model.is
 *  @property {Certificates_Has} model.has
 */

export default class AddLayawayPayment extends Framework.Components.Object {
	constructor(props) {
		super(props, Types.Layaways_Payments._name);
		this.state.focused = "";

	}
	componentDidMount(){
		this.state.cash_amount ="0.00";
		this.state.check_amount ="0.00";
	}
	handleAmountFocus = (event) => {
		if (this.state[event.currentTarget.name] === "0.00") this.setState({[event.currentTarget.name]: this.state.remaining});
	};
	handleSubmit =()=> {
		let payment = {
			cash_amount: this.state.cash_amount,
			check_amount: this.state.check_amount,
		}

			this.props.afterSave()
			this.props.handleAddPayment(payment)
	}
	form = () => {
		/**
		 * @type {Layaways_Payments_Properties} properties
		 */
		let properties = this.model.properties;
		/**
		 *  @type {Layaways_Payments_Object} object
		 */
		let object = this.state;
		let remaining = this.props.total||(0.00).toFixed(2)
		if (!isNaN(object.cash_amount) && object.cash_amount) remaining -= parseFloat(object.cash_amount)
		if (!isNaN(object.check_amount) && object.check_amount) remaining -= parseFloat(object.check_amount)
		remaining = Math.ceil(remaining * 100) / 100;
		this.state.remaining = remaining.toFixed(2);

		return (
			<div>

				<fieldset>
					<div className="row">
						<div className="col-6">

							<h4 className="section-title">Manual Credit Card Entry for
								$ {!isNaN(remaining) ? remaining.toFixed(2) : '0.00'}</h4>
							<div className="row">

									{/*<Cards*/}
									{/*	// number={object.credit_card_number}*/}
									{/*	name={object.credit_card_name}*/}
									{/*	expiry={object.credit_card_expiry}*/}
									{/*	cvc={object.credit_card_cvc}*/}
									{/*	focused={object.focused}*/}
									{/*/>*/}
									<div className={'col'}>
										<div className={"form-group"}>
											<Framework.Elements.TextField
												name={"credit_card_number"}
												label={"Card Number"}
												value={object.credit_card_number}
												error={object.credit_card_number_error}
												property={properties.credit_card_number}
												update={this.handleTextFieldPropertyUpdate}
											/>
										</div>
										<div className={"form-group"}>
											<Framework.Elements.TextField
												name={"credit_card_name"}
												label={"Card Holder Name"}
												property={properties.credit_card_name}
												value={object.credit_card_name}
												error={object.credit_card_name_error}
												update={this.handleTextFieldPropertyUpdate}
											/>
										</div>
										<div className="row">
											<div className="col">
												<div className={"form-group"}>
													<Framework.Elements.TextField
														name={"credit_card_expiry"}
														label={"Exp."}
														value={object.credit_card_expiry}
														error={object.credit_card_expiry_error}
														property={properties.credit_card_expiry}
														update={this.handleTextFieldPropertyUpdate}
													/>
												</div>
											</div>
											<div className="col form-group">
												<div className={"form-group"}>
													<Framework.Elements.TextField
														name={"credit_card_cvc"}
														label={"CVC"}
														value={object.credit_card_cvc}
														error={object.credit_card_cvc_error}
														property={properties.credit_card_cvc}
														update={this.handleTextFieldPropertyUpdate}
													/>
												</div>
											</div>
											<div className="col">
												<div className={"form-group"}>
													<Button size="md" block={true} color="primary"
													        onClick={() => this.checkout("POST_CARD")}>
														Process
													</Button>
												</div>
											</div>
										</div>
										<p style={{textAlign: 'center'}}>
											Click <strong>Process</strong> to run the credit card manually
											or
											click <strong>Charge Card</strong> to process the remaining
											balance
											on the Credit Card Terminal.
										</p>
									</div>
							</div>
						</div>
						<section className={'col-6'}>
							<h4 className="section-title"> Payments for
								$ {!isNaN(remaining) ? remaining.toFixed(2) : '0.00'}</h4>
							<div className={'row'}>
								<div className={'col'}>
									<div className={'form-group'}>
										<Framework.Elements.DollarAmount
											name={"cash_amount"}
											value={object.cash_amount}
											error={object.cash_amount_error}
											label={"Cash"}
											property={properties.cash_amount}
											update={this.handleTextFieldPropertyUpdate}
											handleFocus={this.handleAmountFocus}
											margin={"dense"}
										/>
									</div>
								</div>
							</div>
							<div className={'row'}>
								<div className={'col '}>
									<div className={'form-group'}>
										<Framework.Elements.DollarAmount
											name={"check_amount"}
											label={"Check"}
											property={properties.check_amount}
											value={object.check_amount}
											error={object.check_amount_error}
											update={this.handleTextFieldPropertyUpdate}
											handleFocus={this.handleAmountFocus}
											margin={"dense"}
										/>
									</div>
								</div>
							</div>
						</section>
						<div className="col-6">
						<div className={'row'}>
							<div className={'col'}>
								<div className={"form-group"}>
									<Framework.Elements.TextField
										name={"description"}
										label={"Description"}
										value={object.description}
										error={object.description_error}
										property={properties.description}
										update={this.handleTextFieldPropertyUpdate}
										multiline={true}
									/>
								</div>
							</div>
							</div>
						</div>
					</div>
				</fieldset>


				<div className={classNames("loader", {'active': this.state.loading})}>
					<ClipLoader color={"#4A4A4A"} loading={this.state.loading}/>
				</div>
			</div>
		)
	}
}
