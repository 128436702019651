import React from 'react';
import {Button} from "reactstrap";
import {Card, CardContent} from "@material-ui/core";
import {Collections, Objects} from "components"
import Framework from "@beardeddevops/react.framework";
import APPCONFIG from "../../constants/Config";


export default class LayawayView extends React.Component {
	constructor(props) {
		super(props);

	}


	render() {
		return <>
			<Card>
				<CardContent>
					<div>
						<Collections.LayawayEntries
							parent={this.props.id}
							history={this.props.history}
						/>
					</div>
					<div style={{marginTop:"10px"}}>
						<Objects.Layaway
							id={this.props.id}
							history={this.props.history}
						/>
					</div>


				</CardContent>
			</Card>

		</>

	}
}

