import React from 'react';
import {Switch, Redirect} from 'react-router-dom';

import ErrorBoundaryRoute from "../../../../containers/ErrorBoundaryRoute";
import Collection from './components/Sessions';

export default ({match, locations }) => (
	<div>
		<Switch>
			<ErrorBoundaryRoute exact path={`${match.url}`}>
				<Collection locations={locations}/>
			</ErrorBoundaryRoute>

			<ErrorBoundaryRoute>
				<Redirect to={`${match.url}`}/>
			</ErrorBoundaryRoute>
		</Switch>
	</div>
);
