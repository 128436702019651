import React from "react";
import {Button} from "reactstrap";

// noinspection JSUnresolvedVariable
/**
 * @param {Inventories_Connected_Properties} product
 * @param remove
 */
export default ({product, remove}) => (
	<div
		key={product.pkey}
		className="form-group"
		style={{
			backgroundColor: "#f9f9f9",
			paddingTop: "4px",
			paddingBottom: "4px",
			paddingLeft: "8px",
			marginBottom: "8px",
			fontSize: "14px",
			borderRadius: "3px !important",
			border: "1px solid #efefef",
		}}
	>
		{product.serial_number}
		<Button
			id={product.pkey}
			color={"danger"}
			size={"sm"}
			style={{lineHeight: "1.4", marginRight: "1px", marginTop: "-3px"}}
			className={"pull-right"}
			onClick={remove}
		>
			<i className={"fa fa-times"}/>
		</Button>
	</div>
);
