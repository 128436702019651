import React from "react";

import classNames from "classnames";

import ClipLoader from 'react-spinners/ClipLoader';

import moment from "moment";

import API from "@beardeddevops/react.api";
import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import {Button} from "reactstrap";

/**
 *  @property {Users_Object} state
 *  @property {object} model
 *  @property {Users_Properties} model.properties
 *  @property {Users_Is} model.is
 *  @property {Users_Has} model.has
 */
export default class UserObject extends Framework.Components.Object {

	constructor(props) {
		/**
		 *  @typedef {Users_Object} this.state
		 */
		super(props, Types.Users._name);

		this.state.locations = [];
		// this.state.access = 3;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		super.componentDidUpdate(prevProps, prevState, snapshot);

		let object = this.state;

		if (typeof this.props.updateName === 'function') {
			this.props.updateName(object.name_first + " " + object.name_last);
		}
	}


	// componentDidMount() {
	// 	let Locations = new API.BuildClass(Types.Locations._name, API.RequestType.READ_ALL);
	// 	// noinspection JSIgnoredPromiseFromCall
	// 	Locations.handleSubmit(null, API.RequestType.READ, function (data) {
	// 		// You can retrieve your data however you want, in this case, we will just use some local data.
	// 		this.setState({'locations': data.items});
	// 		if (this.state.pkey !== null)
	// 			this.loadSelf();
	// 	}.bind(this));
	// }

	handleProcess = event => {
		let shouldContinue = API.Validator.process(this, this.requestType, true, event ? event.target.name : null);

		if (event && (event.target.name === 'password' || typeof event.target.name === 'undefined')) {
			if (!this.state.pkey || !this.state.pkey.value) { //If its a new user
				if (this.state.password.value === '') { //Then pass is required
					shouldContinue = false;
					API.Validator.constructor.process_error(this, 'password', 'Password is required');
				}
			}
		}

		return shouldContinue;
	};

	handleEnable = () => {
		this.state.access = 1;
		this.handleSubmit();
	}

	form = () => {
		let {properties} = this.model;

		/**
		 *  @type {Users_Object} object
		 */
		let object = this.state;

		return (
			<div>



				<fieldset>
					<h4 className="section-title">User</h4>
					<div className={"row"}>
						<div className="col-md">
							<div className={"row"}>
								<div className={"col-md"}>
									<div className="form-group">
										<Framework.Elements.TextField
											name={"name_first"}
											label={"First Name"}
											value={object.name_first}
											error={object.name_first_error}
											property={properties.name_first}
											update={this.handleTextFieldPropertyUpdate}
										/>
									</div>
								</div>
								<div className={"col-md"}>
									<div className="form-group">
										<Framework.Elements.TextField
											name={"name_last"}
											label={"Last Name"}
											value={object.name_last}
											error={object.name_last_error}
											property={properties.name_last}
											update={this.handleTextFieldPropertyUpdate}
										/>
									</div>
								</div>
							</div>
							<div className="form-group">
								<Framework.Elements.Select
									shouldLoad={this.state.loaded}
									text={'name'}
									label={"Location"}
									value={object.location}
									error={object.location_error}
									property={properties.location}
									update={this.handleSelectPropertyUpdate}
									nullable={true}

								/>
							</div>
							<div className="form-group">
								<Framework.Elements.Select
									shouldLoad={this.state.loaded}
									text={'name'}
									label={"Access"}
									value={object.access}
									model={Types.Users_Accesses}
									error={object.access_error}
									property={properties.access}
									update={this.handleSelectPropertyUpdate}
									nullable={true}
								/>
							</div>
							<div className="form-group">
								<Framework.Elements.TextField
									name={"employee_id"}
									label={"Employee ID"}
									value={object.employee_id}
									error={object.employee_id_error}
									property={properties.employee_id}
									update={this.handleTextFieldPropertyUpdate}
								/>
							</div>
						</div>
						<div className="col-md">
							<div className="form-group">
								<Framework.Elements.TextField
									name={"email"}
									label={"Email"}
									value={object.email}
									error={object.email_error}
									property={properties.email}
									update={this.handleTextFieldPropertyUpdate}
								/>
							</div>
							<div className="form-group">
								<Framework.Elements.TextField
									name={"password"}
									label={object.pkey ? "New Password" : "Password"}
									value={object.password}
									error={object.password_error}
									property={properties.password}
									update={this.handleTextFieldPropertyUpdate}
								/>
							</div>
						</div>
					</div>
				</fieldset>


				<div className={classNames("loader", {'active': properties.loading})}>
					<ClipLoader color={"#4A4A4A"} loading={properties.loading}/>
				</div>
			</div>
		)
	}

	render() {
		let hasModal = typeof this.props.hasModal !== 'undefined' ? this.props.hasModal : false;
		if (hasModal) {
			return this.form();
		}
		/** @type {Users_Object} object */
		let object = this.state;

		return (
			<Card style={{overflow: 'visible'}}>
				<CardContent style={{paddingBottom: 0}}>
					{this.form()}
					<hr style={{marginBottom: 0}}/>
				</CardContent>
				<CardActions style={{justifyContent: 'flex-end', padding: '14px'}}>
					{this.noDelete && object.access !== null &&
						<Button color="danger" onClick={this.handleDelete}>Disable</Button>
					}
					{object.access !== null && <Button color="primary" onClick={this.handleSubmit}>Save</Button>}
					{object.access === null && <Button color="primary" onClick={this.handleEnable}>Re-Enable</Button>}
				</CardActions>
			</Card>
		)
	}

}
