
import Item from "components/Elements/Item";
import ItemLookup from "components/Elements/ItemLookup";
import Product from "components/Elements/Product";
import ProductLookup from "components/Elements/ProductLookup";


const Elements = {
	Item:Item,
	ItemLookup: ItemLookup,
	Product:Product,
	ProductLookup:ProductLookup

};

export default Elements;
