import React from 'react';
import {withRouter} from "react-router";
import {Link} from 'react-router-dom';

import {Button} from "reactstrap";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import API from "@beardeddevops/react.api";
import APPCONFIG from 'constants/Config';
import Framework from "@beardeddevops/react.framework";
import Types from "constants/Types";

/**
 *  @property {Accounts_Object} state
 *  @property {object} model
 *  @property {Accounts_Properties} model.properties
 *  @property {Accounts_Is} model.is
 *  @property {Accounts_Has} model.has
 */
class Login extends Framework.Components.Object {
  constructor(props) {
    super(props, Types.Accounts._name);
    this.model.requestType = API.RequestType.READ;
    this.brand = APPCONFIG.brand;
    this.shouldAutoLoad = false;
  }

  handleSubmit = async (event) => {
	  if (typeof event !== 'undefined') event.preventDefault();
	 	  this.setState({loading: true});
	  if (this.handleProcess(null, API.RequestType.READ)) {
		  await this.model.submit(this, API.RequestType.READ);
		  this.props.history.push('/dashboard');
	  } else {
		  this.setState({loading: false});
	  }
	  return false;
  }

  handleKeyPress = (event) => {
	  if(event.key === 'Enter') {
		  this.handleSubmit();
	  }
  }

  render() {
    let {properties} = this.model;
	  /**
	   *  @type {Accounts_Object} object
	   */
    let object = this.state;
    let brand = this.brand;

    return (
      <div className="body-inner"
           tabIndex={0}
           onKeyDown={this.handleKeyPress}>
        <Card>
          <CardContent>
            <section className="logo text-center">
              <h1><a href="/">{brand}</a></h1>
            </section>

            <fieldset>
              <div className="form-group">
                <Framework.Elements.TextField
                  value={object.email}
                  error={object.email_error}
                  property={properties.email}
                  update={this.handleTextFieldPropertyUpdate}
                  variant={"standard"}
                />
              </div>
              <br/>
              <div className="form-group">
                <Framework.Elements.TextField
                  value={object.password}
                  error={object.password_error}
                  property={properties.password}
                  update={this.handleTextFieldPropertyUpdate}
                  variant={"standard"}
                  type={"password"}
                />
              </div>
              <br/>
            </fieldset>

          </CardContent>
          <CardActions className={"d-flex justify-content-end mr-2"}>
            <Button onClick={this.handleSubmit} type={"submit"} id={"button-login"} style={{marginLeft:"50px"}} color="primary" size={"md"}
                    block={false}>Login</Button>
          </CardActions>
        </Card>

        <div className="additional-info">
          {/*<Link to="/register" style={{'color': '#525252'}}>Sign up</Link>*/}
          {/*<span className="divider-h" style={{'borderRightColor': '#525252'}}/>*/}
          <Link to="/forgot-password" style={{'color': '#525252'}}>Forgot your password?</Link>
          <span className="divider-h" style={{'borderRightColor': '#525252'}}/>
          <Link to="/information" style={{'color': '#525252'}}>Information</Link>
        </div>

      </div>
    );
  }
}

const LoginRoute = withRouter(Login);
const Page = () => (
  <div className="page-login">
    <div className="main-body">
      <div key="1">
        <LoginRoute/>
      </div>
    </div>
  </div>
);

export default Page;
