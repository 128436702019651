import React from 'react';
import {Switch, Redirect} from 'react-router-dom';

import ErrorBoundaryRoute from "../../../../containers/ErrorBoundaryRoute";


import Collection from './components/Inventories';
import Object from './components/Inventory';
import SerializedComponent from './components/Serialized';

export default ({ match, locations}) => (
	<div>
		<Switch>

			<ErrorBoundaryRoute exact path={`${match.url}`}>
				<Collection locations={locations}/>
			</ErrorBoundaryRoute>

			<ErrorBoundaryRoute path={`${match.url}/serialized/:id`}>
				<SerializedComponent locations={locations} baseUrl={`${match.url}`}/>
			</ErrorBoundaryRoute>

			<ErrorBoundaryRoute path={`${match.url}/:id`}>
				<Object locations={locations} baseUrl={`${match.url}`}/>
			</ErrorBoundaryRoute>

			<ErrorBoundaryRoute>
				<Redirect to={`${match.url}`}/>
			</ErrorBoundaryRoute>

		</Switch>
	</div>
);
