import React from 'react';

import { Button } from 'reactstrap';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import View from '../../../components/View';
import InventorySerialized from 'components/Objects/InventorySerialized';
import Framework from "@beardeddevops/react.framework"
import APPCONFIG from "../../../../../constants/Config";

export default class Serialized extends View {

	componentDidMount() {
		this.setModel("Serialized");
	}
	render() {
		return (

			<div className="container-fluid">
				 <Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
					<Card>
						<CardContent style={{paddingBottom: 0}}>
							<InventorySerialized
								history={this.props.history}
								base={this.props.base}
								id={this.props.match.params.id}
								shouldSave={this.state["saveModelSerialized"]}
								shouldDelete={this.state["deleteModelSerialized"]}
								attemptedSave={this.resetModal("Serialized")}
							/>
							<hr style={{marginBottom: 0}} />
						</CardContent>
						<CardActions style={{justifyContent: 'flex-end', padding: '16px'}}>
							<Button color="danger" onClick={() => this.deleteModel("Serialized")()}>Delete</Button>
							<Button color="primary" onClick={() => this.saveModel("Serialized")()}>Save</Button>
						</CardActions>
					</Card>
				</Framework.Errors.General>
			</div>
		)
	}
};