import React from "react";
import {Link} from "react-router-dom";
import {Objects, Types} from "components";
import Framework from "@beardeddevops/react.framework";
import API from "@beardeddevops/react.api";

// noinspection JSUnresolvedVariable
export default ({
	                entry,
	                product,
	                onDelete,
	                onUpdate,
	                modalUpdate,
	                openModal,
	                reportClose,
	                history,
                }) => {
	return (
		<div key={product.pkey} className="portlet box blue-hoki">
			<div className="portlet-title">
				<div className="caption">
					<i className="fa fa-barcode"/>&nbsp;&nbsp;
					<Link to={"/inventory/" + product.pkey}
					      style={{color: 'white'}}>{product.skus.name}</Link>
					<span>&nbsp;&nbsp;x&nbsp;&nbsp;</span>
					{product.quantity}
				</div>
				{onDelete && <div className="tools">
					<a href='#'
					   onClick={() => onDelete(entry.pkey || null, product)}
					   className="remove"
					   style={{color: 'white'}}><i className={"fa fa-remove"}/></a>
				</div>}
			</div>
			<div className="portlet-body">

				<a onClick={openModal} className="btn btn-sm blue-hoki pull-right">View</a>
				<h5 style={{marginTop: 0}}>{product.skus.sku_classes.name}</h5>
			</div>

			<Framework.Elements.UniversalModal
				history={history}
				name={"Inventory"}
				id={product.pkey}
				object={Objects.Inventory}
				toggle={modalUpdate}
				reportClose={reportClose}
				afterSave={onUpdate}
				size={"xl"}
			/>
		</div>
	)
};
