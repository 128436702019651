import React from "react";
import classNames from "classnames";
import ReactTable from "react-table";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import ClipLoader from 'react-spinners/ClipLoader';
import API from "@beardeddevops/react.api";
import APPCONFIG from "../../constants/Config";
import Framework from "@beardeddevops/react.framework";
import ReactToPrint from 'react-to-print';
import Types from 'constants/Types';
import {Card, CardContent, TextField} from "@material-ui/core";

class Products extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Inventories_Connected._name, API.RequestType.READ_ALL);
		super(props, model);
		// this.state.limit = 16;
		this.state.showPrice = false;
		this.state.ffl_price = null;
		this.state.selectedPkey = null;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		super.componentDidUpdate(prevProps, prevState, snapshot);
		if (prevState.search !== this.state.search) {
			this.reloadTable();
		}
	}

	addToCart = (product, serial) => () => {
		let item = new API.BuildClass(Types.Checkouts_Products._name, API.RequestType.CREATE);
		item.properties.product.value = product;
		item.object= {
			product: product,
			quantity:'1',
		}
		// noinspection JSIgnoredPromiseFromCall
		item.submit(null, API.RequestType.CREATE).then(() => this.props.rootState.set({session_items_count: this.props.rootState.session_items_count + 1}));
		//this.props.history.push('/checkout')
	};

	handleToggleModal = (modal) => {
		this.setState({[modal]: !this.state[modal]});
	}

	handleUpdatePrice = async () => {
		//call api that calls fflboss to update the price
		let model = new API.BuildClass(Types.Inventories_Connected._name, API.RequestType.UPDATE);
		this.update = 'POST_PRICE';
		await model.submit(this, this.update);
		this.setState({key: Math.random()});
	}

	render() {
		const {data, pages, loading,limit} = this.state;
		/**
		 * @type {Object} r
		 * @property {object} caliber_name
		 * @property {object} caliber_other
		 */
		let columns =[
			{
				Header: "Type",
				id: "type",
				accessor: r => {
					return r.type
				},
			},
			{
				Header: "Manufacturer",
				id: "manufacturer",
				accessor: r => r.manufacturers?.name || r.manufacturer_other
			},
			{
				Header: "Model",
				id: "model",
				accessor: r => r.model
			},
			{
				Header: "Caliber",
				id: "caliber",
				accessor: r => r.calibers?.name || r.caliber_other,
			},
			{
				Header: "Serial",
				id: "serial_number",
				accessor: r => <a href={APPCONFIG.settings.fflBossURL() + "/products/"+r.binary_id+'-' + r.pkey} target={"_blank"}>
					{r.serial_number}
					<Framework.Printable.Label
						ref={el => (this['printable' + r.pkey] = el)}
						prefix="P" code={r.pkey} label={r.serial_number}/>
				</a>
			},
			{
				Header: "SKU",
				id: "sku",
				accessor: r => r.sku
			},
			{
				Header: "Price",
				id: "price_list",
				accessor: r => r.price_list,
				Cell: props => <span>${props.value}</span>
			},
			{
				Header: "Status",
				id: "product_statuses.name",
				accessor: r => r.product_statuses.name
			},
			{
				Header: "Location",
				id: "location",
				accessor: r => r.location
			},
			{
				Header: "Label",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 80,
				Cell: props =>
					<ReactToPrint
						trigger={() =>
							<Button color="secondary pull-right" size={"sm"} block={true}><i
								className={"fa fa-tag"}/>&nbsp;&nbsp;Print</Button>
						}
						content={() => this['printable' + props.original.pkey]}
						copyStyles={false}
						pageStyle={'@media print { body { -webkit-print-color-adjust: exact; margin: 0 } @page { margin: 0 }}'}
						bodyClass={""}
					/>
			},
			{
				Header: "Checkout",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 80,
				Cell: props => {
					return (
						<Button color="primary pull-right"
						        onClick={this.addToCart(props.original.pkey,props.original.serial)}
						        size={"sm"} block={true}><i
							className={"fa fa-shopping-cart"}/>&nbsp;&nbsp;Add</Button>
					);
				}
			},
			{
				Header: "Edit",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 50,
				Cell: props => {
					return (
						<Button color="green-meadow" size={"sm"} onClick={() => {
							this.setState({ffl_price: props.original.price_list, sku: props.original.pkey});
							this.handleToggleModal('showPrice');
						}} block={true}>
							<i className={"fa fa-pencil"}/></Button>
					);
				}
			},
		]

		return (
			<Card>
				<CardContent>
					<div className="table-bar">
						<div className="d-flex">
							{this.renderSearch("Firearms")}
							{this.renderHeaderRight("FFL Boss Products",columns)}
						</div>
					</div>
					<Modal
						isOpen={this.state.showPrice}
						toggle={() => this.handleToggleModal('showPrice')}
						size={"sm"}
					>
						<ModalHeader>
							Edit FFLBoss Firearm Price
						</ModalHeader>
						<ModalBody>
							<TextField
								fullWidth={true}
								size={"small"}
								label={"Price"}
								value={this.state.ffl_price}
								onChange={(e) =>
									this.setState({ffl_price: e.currentTarget.value})}
								variant="outlined"
							/>
						</ModalBody>
						<ModalFooter>
							<Button color={'primary'} onClick={() => {
								this.handleToggleModal('showPrice')
								this.handleUpdatePrice().then();
							}}>
								Save
							</Button>
							<Button color={'secondary'} onClick={() => this.handleToggleModal('showPrice')}>
								Cancel
							</Button>
						</ModalFooter>
					</Modal>
					<ReactTable
						key={this.state.key}
						columns={columns}
						manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={data}
						pages={pages} // Display the total number of pages
						loading={loading} // Display the loading overlay when we need it
						loadingText="Requesting Data"
						onFetchData={this.fetchData} // Request new data when things change
						expanderDefaults={{
							sortable: true,
							resizable: true,
							filterable: false,
							width: undefined
						}}
						defaultSorted={[
							{
								id: this.model.sort,
								desc: this.model.order === 'desc'
							}
						]}
						pageSize={limit}
						showPagination={pages > 1}
						showPaginationTop= {false}
						showPaginationBottom= {true}
						showPageSizeOptions= {true}
						onPageSizeChange={this.onPageSizeChange}
						defaultPageSize={limit}
						className="-striped -highlight"
					/>
					{APPCONFIG.settings.isDev() ? null :
						<div className={classNames("tabled", "loader", {'active': loading})}>
							<ClipLoader color={"#4A4A4A"} loading={loading}/>
						</div>
					}
				</CardContent>
			</Card>
		)
	}
}
export default Framework.Components.withRootState(Products);
