import React from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";

import ReactTable from "react-table";
import {Button} from "reactstrap";
import ClipLoader from 'react-spinners/ClipLoader';
import {Card, CardContent} from "@material-ui/core";

import API from "@beardeddevops/react.api";
import APPCONFIG from "../../constants/Config";
import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';

import moment from "moment";

export default class LayawayPayments extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Layaways_Payments._name, API.RequestType.READ_ALL);
		super(props, model);

		this.state.limit = 5;
		this.limit = 5;
	};

	render() {
		const {data, pages, loading,limit} = this.state;
		const columns = [
			{
				//Todo: The dates that show are the date the layaway was created, not the payment itself.
				Header: "Date",
				id: "created",
				accessor: obj => {
					return obj.created ? moment(obj.created).local().format("MM/DD/YYYY HH:MM  A") : '-'
				}
			},
			{
				Header: "Total",
				id: "amount",
				accessor: r => r.amount
			},
			{
				Header: "Cash",
				id: "cash_amount",
				accessor: r => r.cash_amount
			},
			{
				Header: "Check",
				id: "check_amount",
				accessor: r => r.check_amount
			},
			{
				Header: "Card",
				id: "processor_amount",
				accessor: r => r.processor_amount
			},
			{
				Header: "Register",
				id: "register",
				accessor: r => r.registers.name
			},
			{
				Header: "Description",
				id: "description",
				accessor: r => r.description
			},

		];
		return (<>
				<div className="table-bar">
					<div className="d-flex">
						{/*{this.renderSearch("Payments")}*/}
						{this.renderHeaderRight("LayawayPayments",columns)}
					</div>
				</div>
				<ReactTable
					key={this.state.key}
					columns={columns}
					manual // Forces table not to paginate or sort automatically, so we can handle it server-side
					data={data}
					pages={pages} // Display the total number of pages
					loading={loading} // Display the loading overlay when we need it
					loadingText="Requesting Data"
					onFetchData={this.fetchData} // Request new data when things change
					expanderDefaults={{
						sortable: true,
						resizable: true,
						filterable: false,
						width: undefined
					}}
					defaultSorted={[
						{
							id: this.model.sort,
							desc: this.model.order === 'desc'
						}
					]}
					showPagination= {true}
					showPaginationTop= {false}
					showPaginationBottom= {true}
					showPageSizeOptions= {true}
					defaultPageSize={limit}
					pageSize={limit}
					className="-striped -highlight"
				/>
				{APPCONFIG.settings.isDev() ? null :
					<div className={classNames("tabled", "loader", {'active': loading})}>
						<ClipLoader color={"#4A4A4A"} loading={loading}/>
					</div>
				}
			</>

		)
	}
}
