import React from "react";
import ReactTable from "react-table";
import API from "@beardeddevops/react.api";
import APPCONFIG from "../../constants/Config";
import Framework from "@beardeddevops/react.framework";
import Types from "constants/Types";
import moment from "moment";
import {Card, CardContent} from "@material-ui/core";

class TransactionRefunds extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Transactions_Refunds._name, API.RequestType.READ_ALL);
		super(props, model);
		this.state.limit = 5;
		this.limit = 5;
	}

	componentDidMount() {
		super.componentDidMount();
		this.fetchData().then();
	}

	render() {
		const {data, pages, limit, loading} = this.state;
		let user = this.props.rootState.name;

		const columns = [
			{
				//todo: need to implement consignments once they are able to be refunded
				Header: "Name",
				id: "item",
				accessor: r => r.item ? r.transaction_items.name : r.transaction_products.name
			},
			{
				Header: "Quantity",
				id: "quantity",
				accessor: r => r.quantity,
			},
			{
				Header: "Refund Amount",
				id: "amount",
				accessor: r => r.amount,
			},
			{
				Header: "Date",
				id: "created",
				accessor: r => {
					return moment(r.created).local().format("MM/DD/YYYY hh:mm A")
				}
			},
			{
				Header: "User",
				id: "user",
				accessor: r => r.creator = user
			}
			,
			{
				Header: "Method",
				id: "method",
				accessor: r => r.method,
			},
			{
				Header: "Note",
				id: "note",
				accessor: r => r.note,
			},

		];

		return (
			<>
				{data.length > 0 &&
					<Card>
						<CardContent>
							<div className="box-body">
								<div className="table-bar">
									<div className="d-flex">
										{this.renderHeaderRight("Transaction Items", columns)}
									</div>
								</div>
								<ReactTable
									key={this.state.key}
									columns={columns}
									manual // Forces table not to paginate or sort automatically, so we can handle it server-side
									data={data}
									pages={pages} // Display the total number of pages
									loading={loading} // Display the loading overlay when we need it
									loadingText="Requesting Data"
									onFetchData={this.fetchData} // Request new data when things change
									onPageSizeChange={this.onPageSizeChange}
									onSortedChange={this.onSortedChange}
									page={this.state.page}
									onPageChange={this.onPageChange}
									expanderDefaults={{
										sortable: true,
										resizable: true,
										filterable: false,
										width: undefined
									}}
									onExpandedChange={this.onExpandedChange}
									defaultSorted={[
										{
											id: this.model.sort,
											desc: this.model.order === 'desc'
										}
									]}
									showPagination={true}
									showPaginationTop={false}
									showPaginationBottom={true}
									showPageSizeOptions={true}
									defaultPageSize={limit}
									pageSize={limit}
									pageSizeOptions={[5, 20, 50, 100, 500, 1000]}
									className="-striped -highlight"
								/>
							</div>
							{APPCONFIG.settings.isDev() ? null :
								<Framework.Elements.LoadingSpinner isLoading={loading}/>
							}
						</CardContent>
					</Card>}
			</>
		)
	}
}

export default Framework.Components.withRootState(TransactionRefunds);
