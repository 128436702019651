import React from "react";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';

import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';
import API from "@beardeddevops/react.api";
import {toast} from "react-toastify";

export default class TransactionRefund extends Framework.Components.Object {
	constructor(props) {
		/** @typedef {Transactions_Object} this.state */
		super(props, Types.Transactions_Refunds._name);
		// this.state.quantity = this.state.restock = this.props.quantity - this.props.quantity_refunded;
		this.state.refund_amount = 0;
		this.state.taxable_refund_amount = 0;
		this.state.nontaxable_refund_amount = 0;
		this.state.key = Math.random();
		this.state.original_transaction_total = 0;
		this.state.original_transaction_method = "cash";
		this.state.total_tax_percent = 0;
	}

	componentDidMount() {
		let first_item = this.props.items[0];
		//get total tax percentage from transaction
		let total_tax_percent = parseFloat(first_item.tax_rate_city) + parseFloat(first_item.tax_rate_county) + parseFloat(first_item.tax_rate_special) + parseFloat(first_item.tax_rate_state);

		//see if the transaction had a card on it, if so, we don't show the card refund option
		let original_transaction_method = first_item.transactions.payment_method;

		//get the transaction total here to validate we aren't going past that
		let original_transaction_total = first_item.transactions.amount;

		//set original quantity for validation purposes
		let items = this.props.items.map((item) => {
			//instantiate error here
			item.quantity_error = "";
			item.original_quantity = item.product ? 1 : (item.quantity - (item.refund_quantity || 0));
			item.quantity = item.product ? 1 : (item.quantity - (item.refund_quantity || 0));
			item.new_refund_amount = 0;
			item.new_refund_amount_error = "";
			return item;
		})

		//loads items from transaction into state
		this.setState({items, original_transaction_total, original_transaction_method, total_tax_percent})
	}

	handleSubmit = (event) => {
		this.state.transaction = this.props.transaction

		//check if refund amount is bigger than original transaction total
		if(this.state.amount > this.state.original_transaction_total){
			API.ToastId = toast.error('Refund amount is larger than Refunded Amount. Original total was: ' + this.state.original_transaction_total, {
				position: toast.POSITION.TOP_CENTER
			});
			return;
		}

		let refunded_items = this.state.items.filter((item) => {
			if(item.refunded){
				return true;
			}
		});

		if(refunded_items.length === 0){
			API.ToastId = toast.error('You must select an item to refund', {
				position: toast.POSITION.TOP_CENTER
			});
			return;
		}

		//set zeros for now TODO: (these need to be removed under the new system)
		this.state.quantity = 0;
		this.state.restock = false;
		//if they only select amounts from the list, set amount to that
		if(!this.state.amount){
			this.state.amount = this.state.refund_amount;
		}
		// this.state.item = this.props.entry

		//map through items, only send ones that got checked
		this.state.items = refunded_items;

		super.handleSubmit(event)
	}

	handleRefundAmount = (object) => {
		let amount = 0.00;
		//if taxable
		if (object.taxable) {
			let item_price = ((parseFloat(object.price) * parseFloat(object.product ? 1 : object.quantity)));
			let discount_percentage = (1 - (object.transactions.discount_percentage) / 100);
			let price = ((item_price * discount_percentage) - parseFloat(object.discount_amount ?? 0.00)).toFixed(2);
			let tax = Math.ceil((price * (this.state.total_tax_percent / 100)) * 100) / 100;
			amount= (parseFloat(price) + parseFloat(tax)).toFixed(2);
		} else {
			amount = ((parseFloat(object.price) * parseFloat(object.product ? 1 : object.quantity)) - parseFloat(object.discount_amount ?? 0.00)).toFixed(2);
		}
		return amount;
	}

	handleItemChange = async (item, property, value) => {
		let total_refund_amount = 0;
		//loop through to change property of the product, object clone might be overkill, but it's what worked
		let items = this.state.items.map((eachItem) => {
			let temp = Object.assign({}, eachItem);
			if (temp.pkey === item.pkey) {
				//if checked off
				if (property === 'checked') {
					if(value) {
						temp.new_refund_amount = this.handleRefundAmount(temp);
					} else {
						temp.new_refund_amount = 0.00;
					}
				}
				//if quantity, don't let them update it to more than the original quantity
				//TODO: Potential problem here is that people complain that they can't enter whatever quantity they want,
				// but that's so we don't accept something that could make it negative (if we do in the future, just return value no matter what)
				 else if(property === 'quantity'){
					if(parseInt(temp.original_quantity) >= value){
						temp[property] = value;
						temp.new_refund_amount = this.handleRefundAmount(temp);
						temp.quantity_error = "";
					} else {
						temp.quantity_error = "Quantity cannot be more than the original transaction, " + temp.original_quantity;
					}
				} else {
					if(typeof value !== undefined && value !== '') {
						temp[property] = value;
					}
				}

				//only add to total refund amount if it is checked
				if(temp.new_refund_amount !== 0.00 && temp.refunded === true) {
					total_refund_amount += parseFloat(temp.new_refund_amount) ?? 0;
				}

				return temp;
			}
			total_refund_amount += parseFloat(eachItem.new_refund_amount) ?? 0;
			return eachItem;
		})
		if(total_refund_amount < 0){
			total_refund_amount = 0.00;
		}
		this.setState({refund_amount: parseFloat(total_refund_amount).toFixed(2), key: Math.random()});
		await this.setState({items})
	}

	handleRefundItemCheck = async (item) => {
		//loop each time this is called to get an accurate refund total
		let taxable_total = 0;
		let nontaxable_total = 0;
		this.state.items.forEach((item) => {
			//if checked off
			if (item.refunded) {
				//if taxable
				if(item.taxable) {
					taxable_total = ((parseFloat(item.price) * parseFloat(item.product ? 1 : item.quantity)) + parseFloat(taxable_total)).toFixed(2);
				} else {
					nontaxable_total = ((parseFloat(item.price) * parseFloat(item.product ? 1 : item.quantity)) + parseFloat(nontaxable_total)).toFixed(2);
				}
			}
		})

		//add tax and non-tax to refund amount
		let tax_total = taxable_total * (this.state.total_tax_percent / 100);
		let tax_amount = Math.ceil(tax_total * 100) / 100;
		let refund_amount = (parseFloat(taxable_total) + parseFloat(nontaxable_total) + parseFloat(tax_amount)).toFixed(2)

		//update the refunded amount here
		await this.handleItemChange(item, 'new_refund_amount', refund_amount);
		// this.setState({refund_amount: refund_amount, key: Math.random()});
	}

	form = () => {
		/**	 @param {Transactions_Refunds_Properties} data */

		let {properties} = this.model;
		/** @type {Transactions_Refunds_Object} object */
		let object = this.state;

		//map through all the items to show them
		const itemList = this.state.items?.map((item) => {
			return (
				<div>
					<div className={"row"}>
						<div className={"col-4"}>
							<div className="form-group" style={{marginTop: -5}}>
								<Framework.Elements.Checkbox
									value={item.refunded}
									property={properties.refunded}
									error={object.refunded_error}
									update={() => {
										item.refunded = !item.refunded;
										this.handleItemChange(item, 'checked', item.refunded).then();
									}}
									color="primary"
									label={item.name}
								/>
							</div>
						</div>
						<div className={"col-2"}>
							<div className="form-group" style={{marginTop: -5}}>
								<Framework.Elements.TextField
									value={item.product ? 1 : item.quantity}
									property={properties.quantity}
									error={item.quantity_error}
									update={async (property, value) => {
										await this.handleItemChange(item, property, value);
										await this.handleRefundItemCheck();
									}}
									color="primary"
									label={"Amount"}
								/>
							</div>
						</div>
						<div className={"col-2"}>
							<div className="form-group" style={{marginTop: -5}}>
								<Framework.Elements.ButtonSwitch
									name={"Test"}
									property={properties.restock}
									value={item.restock}
									error={object.restock_error}
									update={async (property, value) => {
										await this.handleItemChange(item, property, value);
									}}
									labelTrue={'Yes'}
									labelFalse={'No'}
								/>
							</div>
						</div>
						<div className={"col-4"}>
							<div className="form-group">
								<Framework.Elements.DollarAmount
									error={item.new_refund_amount_error}
									property={properties.new_refund_amount}
									placeholder={'0.00'}
									value={item.new_refund_amount}
									label={"Refund Amount"}
									update={async (property, value) => {
										await this.handleItemChange(item, 'new_refund_amount', value);
									}}
									allowNegative={false}
								/>
							</div>
						</div>
					</div>
				</div>
			)
		});

		return (
			<fieldset>
				<h4 className="section-title">Select Items Below to Refund</h4>
				{/*headers for itemList*/}
				<div>
					<div className={"row"}>
						<div className={"col-4"}>
							<h4 className="section-title mdl-typography--text-center">
								Item
							</h4>
						</div>
						<div className={"col-2"}>
							<h4 className="section-title mdl-typography--text-center">
								Quantity
							</h4>
						</div>
						<div className={"col-2"}>
							<h4 className="section-title mdl-typography--text-center">
								Restock?
							</h4>
						</div>
						<div className={"col-4"}>
							<h4 className="section-title mdl-typography--text-center">
								Subtotal
							</h4>
						</div>
					</div>
				</div>
				{itemList}
				<div>
					<div className={"col-6 float-right"}>
						<div className="form-group">
							<Framework.Elements.DollarAmount
								key={this.state.key}
								error={object.amount_error}
								property={properties.amount}
								placeholder={'0.00'}
								value={object.amount || this.state.refund_amount}
								label={"Total Refund Amount"}
								update={this.handleTextFieldPropertyUpdate}
								allowNegative={false}
							/>
						</div>
						{/*TODO: dont let them refund via card unless the transaction was done via card*/}
						<div className={"form-group"}>
							<Framework.Elements.Select
								shouldLoad={true}
								update={this.handleSelectPropertyUpdate}
								property={{...properties.method, class: false, name: 'method'}}
								error={object.method_error}
								value={object.method}
								objects={[
									{pkey: 'cash', name: "Cash"},
									// this is just spreading a blank or the card value in the middle (shows blank if you don't do this)
									...(this.state.original_transaction_method === "card" ? [{pkey: 'card', name: "Card"}] : []),
									{pkey: 'other', name: "Other"},
								]}
								id={'name'}
							/>
						</div>
						<div className={"form-group"}>
							{this.state.method === 'Cash' &&
								<Framework.Elements.Select
									shouldLoad={this.state.loaded}
									object={object.registers}
									value={object.register}
									error={object.register_error}
									model={Types.Registers._name}
									property={properties.register}
									label={'Registers'}
									update={this.handleSelectPropertyUpdate}
								/>
							}
						</div>
							<div className={"form-group"}>
							<Framework.Elements.TextField
								name={"note"}
								label={"Note"}
								multiline={"3"}
								update={this.handleTextFieldPropertyUpdate}
								error={object.note_error}
								property={properties.note}
								value={object.note}
							/>
						</div>
					</div>
				</div>

				<div className={classNames("loader", {'active': properties.loading})}>
					<ClipLoader color={"#4A4A4A"} loading={properties.loading}/>
				</div>
			</fieldset>
		)
	}
}
