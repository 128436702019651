import React from 'react';

import Framework from "@beardeddevops/react.framework";
import {Collections} from "components";


const breadcrumbsStatic = [
	{
		name: 'Dashboard',
		url: '/dashboard',
		icon: 'dashboard',
	},
	{
		name: 'Sales Tax Report',
		url: 'sales_tax_report',
		icon: 'file',
	},
];

export default class SalesTaxReport extends Framework.Components.View {


	constructor(props) {
		super(props);

	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		super.componentDidUpdate(prevProps, prevState, snapshot);
	}

	render() {
		console.log("this.state", this.state)
		console.log("props", this.props)

		return (
			<div className="container-fluid">

				<Framework.Components.PageBar breadcrumbs={breadcrumbsStatic} history={this.props.history}>
				</Framework.Components.PageBar>
					<Collections.SalesTaxReports
						history={this.props.history}
						reloadParent={this.props.reloadParent}
					/>
			</div>
		);
	}
}
