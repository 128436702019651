import React from 'react';
import {Switch, Redirect} from 'react-router-dom';

import ErrorBoundaryRoute from '../../../../containers/ErrorBoundaryRoute';

import Collection from './components/Transactions';
import Object from "./components/Transaction";

export default ({match, locations,account }) => (
	<div>
		<Switch>
			<ErrorBoundaryRoute exact path={`${match.url}`}>
				<Collection account={account} locations={locations}/>
			</ErrorBoundaryRoute>
			<ErrorBoundaryRoute path={`${match.url}/:id`}>
				<Object locations={locations} baseUrl={`${match.url}`}/>
			</ErrorBoundaryRoute>
			<ErrorBoundaryRoute>
				<Redirect to={`${match.url}`}/>
			</ErrorBoundaryRoute>
		</Switch>
	</div>
);
