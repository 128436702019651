import React from "react";
import PropTypes, {any} from "prop-types";

import API from "@beardeddevops/react.api";
import ObjectState from './ObjectState';
import SuggestSearch from '../Elements/SuggestSearch';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TableHeaderRight from './TableHeaderRight';
import {Button} from "reactstrap";

import Paper from "@material-ui/core/Paper";

import Date from '../Elements/Date';

import "react-table/react-table.css";

const dateValueRegex = /\d\d\d\d-\d\d-\d\d\s\d\d:\d\d:\d\d/;

const dateRangeProperties = {
	startDate: {
		error: '',
		fill: true,
		helper: '',
		label: 'Start Date',
		name: 'startDate',
		override: null,
		queryable: false,
		required: false,
		searchable: true,
	},
	endDate: {
		error: '',
		fill: true,
		helper: '',
		label: 'End Date',
		name: 'endDate',
		override: null,
		queryable: false,
		required: false,
		searchable: true,
	}
};

const loadedState = {
	Loading: 1,
	ReadyToLoad: 2,
	Loaded: 3,
}

export default class Collection extends ObjectState {
	// noinspection JSValidateTypes
	static propTypes = {
		id: PropTypes.string, //Bound Book is like the only thing that uses this. SO LEAVE IT
		parent: PropTypes.string,
		parent_binary_id: PropTypes.string,
		grandparent: PropTypes.string,
		grandparent_binary_id: PropTypes.string,

		history: PropTypes.object.isRequired,

		reloadShell: PropTypes.func,
		reloadPage: PropTypes.func,
		reloadSelf: PropTypes.bool,

		// TODO: Replace remaining usage of these with refs so we don't re-render before submit
		saveSelf: PropTypes.any,

		toggleModal: PropTypes.func,

		showDateRangeSearch: PropTypes.bool,

		/**
		 * @type {?ScannedCode}
		 */
		scannedCode: PropTypes.object,

		showCSV:PropTypes.bool,

		//afterSave: PropTypes.func,
		//updateName: PropTypes.func,
	};

	static defaultProps = {
		locations: [],
	};

	/**
	 *
	 * @param props
	 * @param {object} model
	 * @property collections
	 */
	constructor(props, model) {
		super(props);

		if (typeof model === 'string') {
			this.model = new API.BuildClass(model, API.RequestType.READ_ALL);
		} else {
			this.model = model;
		}

		this.state = this.model.collection;

		this.loadIDs();

		this.limit = 20;
		this.state.key = Math.random();
		this.state.page = 0;
		this.state.limit = 20;
		this.state.search = "";
		this.state.searchField = "";
		this.state.filter = [];
		this.state.suggestions = [];
		this.state.expanded = {
			0: true
		};
		this.state.loading = false;
		this.state.startDate = '';
		this.state.endDate = '';
		this.state.filterLocation = null;
		this.state.filterStatus = null;
		this.state.showCSV = this.props.showCSV!==undefined ? this.props.showCSV:true;

		this.request = API.RequestType.READ_ALL;

		this.loaded = loadedState.Loading;

		this.tableStorageName = this.constructor.name;
	}

	componentDidMount() {
		// this.setPresetsFromLocalStorage(null, true);
	}

	// noinspection JSUnusedLocalSymbols
	componentDidUpdate(prevProps, prevState, snapshot) {

		let changed = this.reloadIDs(prevProps, 'collection');
		if (changed) {
			this.loaded = loadedState.Loading;
			console.log('Self was Changed');
			this.model.sort = this.sort ? this.sort : "pkey";
			this.model.order = this.order ? this.order : 'desc';
			this.setState({
				key: Math.random(),
				filter: {},
				search: '',
				searchField: '',
				startDate: '',
				endDate: '',
				limit: this.limit || 20,
				filterLocation: null,
				filterStatus: null
			}, this.setPresetsFromLocalStorage);
			return;
		}

		if (prevProps.saveSelf !== this.props.saveSelf) {
			this.afterSubmit().then();
		}

		if (prevProps.reloadSelf !== this.props.reloadSelf) {
			console.log('Some thing asked me to Reload Self');
			this.reloadTable();
		}

		if (this.loaded === loadedState.Loaded) {
			if (prevState.search !== this.state.search) {
				if (!this.state.search.startsWith('=') || (this.state.search.startsWith('=') && this.state.search.endsWith(';'))) {
					console.log('Changed Search');
					if (this.timeout) clearTimeout(this.timeout);
					this.timeout = setTimeout(() => {
						//search function
						this.reloadTable();
					}, 1000);
				}
			}
			if (prevState.searchField !== this.state.searchField) {
				console.log('Changed Search Field');
				this.reloadTable();
			}
			if (prevState.filter !== this.state.filter) {
				console.log('Changed Filter');
				this.reloadTable();
			}
		}

	}

	/**
	 * @param name
	 * @param originalRequest
	 */
	setPresetsFromLocalStorage = (name, originalRequest) => {
		try {
			let requests = localStorage.getItem('table-preferences-' + this.tableStorageName);
			if (requests) {
				requests = JSON.parse(requests);
				for (let requestName in requests) {
					if (requests.hasOwnProperty(requestName) && (requestName === name || (!name && requestName === 'default'))) {
						let request = requests[requestName];
						if( !request.limit || request.limit === 'All' || isNaN(request.limit) || request.limit === 500 ||  request.limit === 1000 ||  request.limit === '500' ||  request.limit === '1000') {
							request.limit = this.limit;
						}
						this.model.limit = request.limit;
						//this.model.offset = request.offset;
						this.model.sort = request.sort;
						this.model.order = request.order;

						this.setState({
							limit: request.limit,
							filter: request.filter,
							filterLocation: request.filterLocation,
							filterStatus: request.filterStatus,
							search: name ? request.search : '',
							searchField: request.searchField,
							startDate: request.startDate,
							endDate: request.endDate,
						}, () => {
							console.log('Limit is ', this.state.limit);
							if (this.loaded === loadedState.Loading) {
								this.loaded = loadedState.ReadyToLoad;
								console.log('Re-fetching Data', this.loaded);
								this.fetchData(originalRequest).then(() => {});
							} else {
								console.log('Reloading Table after Getting');
								this.reloadTable();
							}
						});

						return true;
					}
				}
			}
		} catch (e) {
			console.log('Failed to set presets with ', e);
		}

		this.loaded = loadedState.Loaded;
		console.log('Reload Table after not Getting');
		this.reloadTable();

		return false;
	}

	updatePresetsToLocalStorage = (name, updatedFilter) => {
		try {
			let requests = localStorage.getItem('table-preferences-' + this.tableStorageName);
			let defaultRequest = {};
			if (requests) {
				requests = JSON.parse(requests);
				defaultRequest = requests['default'] || {};
			} else {
				requests = {}
			}
			requests = {
				...requests,
				[name || 'default']: {
					...defaultRequest,
					//This are Collection State controlled params
					filter: this.state.filter,
					search: name ? this.state.search : '',
					searchField: this.state.searchField,
					startDate: this.state.startDate,
					endDate: this.state.endDate,

					//This are react-table State controlled params
					limit: this.state.limit,
					//offset: this.model.offset,
					sort: this.model.sort,
					order: this.model.order,
					...updatedFilter
				},
			}
			localStorage.setItem('table-preferences-' + this.tableStorageName, JSON.stringify(requests));
			console.log('Table Preferences where updated', this.tableStorageName);
		} catch (e) {
			console.log('Failed to update presets with ', e);
		}
	}

	setColumnsFromLocalStorage = () => {
		try {
			let columns = localStorage.getItem('table-columns-' + this.tableStorageName);
			if (columns) {
				columns = JSON.parse(columns);
				if (typeof columns === 'object') {
					this.setState({
						...this.state,
						...columns
					});
				}
			}
		} catch (e) {
			console.log('Failed to set columns with ', e);
		}
	}

	updateColumnsToLocalStorage = (column) => {
		try {
			let columns = localStorage.getItem('table-columns-' + this.tableStorageName);
			if (columns) {
				columns = JSON.parse(columns);
			} else {
				columns = {}
			}
			columns = {
				...columns,
				...column
			}
			localStorage.setItem('table-columns-' + this.tableStorageName, JSON.stringify(columns));
		} catch (e) {
			console.log('Failed to update columns with ', e);
		}
	}

	onExpandedChange = (newExpanded, index) => {
		if (this.state.expanded[index]) {
			this.setState({
				expanded: {}
			})
		} else {
			this.setState({
				expanded: {[index]: true}
			})
		}
	};

	doSearch = (event, newValue) => {
		let searchText = typeof newValue !== 'undefined' ? newValue : event.target.value;
		searchText = searchText || '';
		this.setState({search: searchText});
	};

	doSearchField = (object) => {
		this.setState({searchField: object});
	};

	searchNow = (event) => {
		let searchText = event.target.value;
		if (searchText.startsWith('=') && !searchText.endsWith(';')) {
			searchText += ';';
		}
		this.setState({search: searchText});
	};


	doFilter = (values, object) => {
		let filter = this.state.filter;
		this.setState({
			filter: {
				...filter,
				[object.name]: values ?
					(Array.isArray(values) ? values.map(value => value.value) : typeof  values ==="object"? values.value:values)
					: []
			},
			['filter' + object.name.charAt(0).toUpperCase() + object.name.slice(1)]: values
		}, () => {
			this.updatePresetsToLocalStorage(null, {
				['filter' + object.name.charAt(0).toUpperCase() + object.name.slice(1)]: values
			})
		});
	}

	getSuggestions = value => {
		const inputValue = value.trim().toLowerCase();
		const toPeriod = inputValue.indexOf('.');
		const toSearch = inputValue.indexOf(':');
		let end = inputValue.length - 1;
		if (toPeriod !== -1 && toSearch !== -1) end = Math.min(toPeriod, toSearch) - 1;
		if (toPeriod !== -1) end = toPeriod - 1;
		if (toSearch !== -1) end = toSearch - 1;
		const inputClean = inputValue.substr(1, end);

		let suggestions = [];
		if (inputValue.length !== 0 && inputValue.charAt(0) === '=') {
			let is = [];

			let properties = Object.keys(this.model.properties)
				.filter((key) => {
					let property = this.model.properties[key];
					// noinspection JSUnresolvedVariable
					if (property && property.searchable && property.searchable !== false && (this.model.set == null || property.set.length === 0 || property.set.includes(this.model.set))) {
						if (property.type === 'fkey') {
							is = [...is, Object.keys(property.properties).sort().map((key) => {
								let object = property.properties[key];
								object.parent_label = property.label;
								object.parent_name = property.name; //class.match(/[A-Z][a-z]+/g).join('_').toLowerCase();
								return object;
							})];
							return false;
						}
						return key.toLowerCase().slice(0, inputClean.length) === inputClean;
					} else {
						return false;
					}
				})
				.sort()
				.map((key) => {
					let property = this.model.properties[key];
					property.name = key;
					property.parent_label = this.model.className;
					property.parent_name = '';
					return property;
				});

			let has = Object.keys(this.model.has)
				.filter((key) => {
					let has = this.model.has[key];
					// noinspection JSUnresolvedVariable
					if (has && has.searchable && has.searchable !== false) {
						return key.toLowerCase().slice(0, inputClean.length) === inputClean
					}
					return false;
				})
				.sort()
				.map((key) => {
					let has = this.model.has[key];
					return Object.keys(has.properties).sort().map((key) => {
						let object = has.properties[key];
						object.parent_label = has.label;
						object.parent_name = has.name;
						return object;
					});
				})
				.flat();

			is = is.flat();

			suggestions = [...properties, ...has, ...is];
		}

		return suggestions;
	};

	getSuggestionValue = suggestion => {
		if (suggestion.parent_name) {
			return "=" + suggestion.parent_name + '.' + suggestion.name + ":";
		} else {
			return "=" + suggestion.name + ":";
		}
	};

	// Use your imagination to render suggestions.
	renderSuggestion = (suggestion, {isHighlighted}) => {
		let type = suggestion.type;
		return (
			<div
				style={{
					padding: '4px 6px',
					backgroundColor: isHighlighted ? '#4859b3' : null,
					color: isHighlighted ? 'white' : 'black'
				}}
			>
				{suggestion.parent_label}.{suggestion.label} - {type}
			</div>
		);
	};

	onSuggestionsFetchRequested = ({value}) => {
		this.setState({
			suggestions: this.getSuggestions(value)
		});
	};

	// Autosuggest will call this function every time you need to clear suggestions.
	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: []
		});
	};

	reloadTable = () => {
		console.log('Something Reloaded the Table');
		this.setState({
			key: Math.random(),
			expanded: {},
		});
	};

	clearFilters = () => {
		this.model.sort = "pkey";
		this.model.order = 'desc';
		this.setState({
			key: Math.random(),
			filter: {},
			search: '',
			searchField: '',
			startDate: '',
			endDate: '',
			page: 0,
			limit: this.limit || 20,
			filterLocation: null,
			filterStatus: null
		});

		localStorage.removeItem('table-preferences-' + this.tableStorageName);
	};

	renderPageSizes = (_ref2) => {
		let pageSize = _ref2.pageSize,
			pageSizeOptions = _ref2.pageSizeOptions,
			rowsSelectorText = _ref2.rowsSelectorText,
			//onPageSizeChange = _ref2.onPageSizeChange,
			rowsText = _ref2.rowsText;
		return React.createElement(
			'span',
			{className: 'select-wrap -pageSizeOptions'},
			React.createElement(
				'select',
				{
					'aria-label': rowsSelectorText,
					onChange: (e) => {
						// noinspection JSUnresolvedVariable
						return this.onPageSizeChange(e.currentTarget.value === 'All' ? 0 : e.currentTarget.value, 0, true);
					},
					value: pageSize
				},
				pageSizeOptions.map(function (option, i) {
					return (
						// eslint-disable-next-line react/no-array-index-key
						React.createElement(
							'option',
							{key: i, value: option},
							option + ' ' + rowsText
						)
					);
				})
			)
		);
	};

	// noinspection JSUnusedLocalSymbols
	/**
	 * @param newPageSize
	 * @param newPage
	 * @param fromSelf
	 */
	onPageSizeChange = (newPageSize, newPage, fromSelf = false) => {
		this.state.limit = newPageSize;
		this.setState({
			key: Math.random(),
			limit: newPageSize,
			page: 0,
		});
	}

	onSortedChange = (newSorted, column, shiftKey) => {
		this.model.sort = newSorted[0]?.id;
		this.model.order = newSorted[0]?.desc === true ? 'desc' : 'asc';
		this.setState({
			key: Math.random(),
			page: 0,
		});
	};

	onPageChange = (pageIndex) => {
		console.log('Changed Page Number');
		this.setState({
			page: pageIndex,
		});
	}

	/**
	 * @param {object }state
	 * @property pageSize
	 * @property page
	 * @property sorted
	 * @property filtered
	 */
	fetchData = async (state) => {
		this.setState({loading: true});
		window.setTimeout(() => {
			this.fetchDataActual(state);
		}, 100);
	}

	/**
	 * @param {object }state
	 * @param overrides
	 * @property pageSize
	 * @property page
	 * @property sorted
	 * @property filtered
	 */
	fetchDataActual = async (state, overrides = []) => {
		// Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
		// You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.

		console.log('Limit is ', this.state.limit);
		console.log('Should Fetch Data', this.loaded);
		console.log('Overrides are:', overrides);

		if (this.loaded === loadedState.Loading) {
			this.setColumnsFromLocalStorage();
			let foundPresets = this.setPresetsFromLocalStorage(null, state);
			if (foundPresets) {
				console.log('Found Presets');
				return;
			} else {
				console.log('Did not find Presets');
				this.loaded = loadedState.Loaded;
			}
		}
		if (this.loaded === loadedState.ReadyToLoad || !state) {
			if( !state ) {
				state = {
					sorted: [],
					page: 0
				};
			}
			if (this.model.sort && this.model.order) {
				state.sorted = [{
					id: this.model.sort,
					...this.model.order === 'desc' ? {desc: this.model.order} : {},
				}];
			}
			this.loaded = loadedState.Loaded;
		}

		console.log('Fetching Data', this.loaded);

		// Request the data however you want.  Here, we'll use our mocked service we created earlier
		let res = await this.requestData(
			state.page,
			state.sorted,
			overrides
		);

		res = await this.formatData(res);

		this.updatePresetsToLocalStorage();

		let others = {};
		if (overrides.length > 0) {
			const validKeys = Object.keys(res);
			overrides.forEach((item) => {
				if (validKeys.includes(item)) {
					others[item] = res[item];
				} else {
					console.log('Collection Error: key ' + item + ' was not found in response data.')
				}
			})
		}

		// Now just get the rows of data to your React Table (and update anything else like total pages or loading)
		this.setState({
			data: res.rows,
			pages: res.pages,
			loading: false,
			...others
		});
	}

	requestData = (page, sorted, overrides = []) => {
		return new Promise((resolve) => {
			this.model.limit = this.state.limit;
			this.model.offset = page * (this.state.limit ? this.state.limit : 0);
			this.model.search = this.state.search;
			let searchParts = this.model.search.split('-');
			if( searchParts.length === 3) {
				let findPrefixNumbers = searchParts[0].search(/[0-9]/);
				let hasPrefixNumbers = findPrefixNumbers !== -1;
				let findMiddleNumbers = searchParts[1].search(/[0-9]/);
				let hasMiddleNumbers = findMiddleNumbers !== -1;
				if (!hasPrefixNumbers && hasMiddleNumbers && searchParts[1].length === 1) {
					searchParts[1] = '000' + searchParts[1];
					this.model.search = searchParts.join('-');
				}
			}
			if( typeof this.state.searchField !== 'undefined' ) {
				this.model.search_field = this.state.searchField['value'] || this.state.searchField;
			}
			this.model.filter = this.state.filter;
			this.model.sort = sorted[0] ? sorted[0].id : "name";
			this.model.order = sorted[0] && sorted[0].desc ? "desc" : "asc";

			this.model.submit(this, this.request).then((data) => {
				let filteredData = data.items;
				let others = {};
				const validKeys = Object.keys(data);
				overrides.forEach((item) => {
					if (validKeys.includes(item)) {
						others[item] = data[item];
					} else {
						console.log('Collection Error: key ' + item + ' was not found in response data.')
					}
				})
				const results = {
					rows: filteredData,
					pages: !this.state.limit ? 1 : Math.ceil(data.total / this.state.limit),
					...others
				};
				resolve(results)
			});

		});
	};

	// To be overridden by Collection components
	formatData = async (res) => {
		return res;
	}

	async afterSubmit(data, request, suppressProcess = false) {
	}

	downloadData = (columns) => {
		let {data} = this.state;
		let columnsToShow = columns
			// Flatten array if there are subheaders present
			.map((col) => {
				return col.columns ? col.columns : col;
			})
			.flat()
			.filter(col => {
				return (typeof col.show === 'undefined' || col.show)
					&& col.className !== 'button-cell'
					&& col.accessor != null;
			});

		return [
			columnsToShow.map(col => col.Header),
			...data.map(row => {
				return columnsToShow.map(col => {
					return typeof col.accessor === 'function' ? col.accessor(row) : col.accessor.split('|').map(part => {
						let value = '';
						part.split('.').forEach(subPart => {
							if (row.hasOwnProperty(subPart)) {
								value = row[subPart];
							} else if (value.hasOwnProperty(subPart)) {
								value = value[subPart];
							}
						});
						return value;
					}).filter(el => el).join(' ');
				})
			})
		];
	};

	handleInputChange = (evt, {newValue}) => {
		this.doSearch(evt, newValue);
	}

	clearSearch = () => this.setState({search: ''})

	
	renderSearch(name) {
		const {search, suggestions} = this.state;

		return (
			<SuggestSearch
				toolTipText={""}
				name={name}
				search={search}
				suggestions={suggestions}
				onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
				onSuggestionsClearRequested={this.onSuggestionsClearRequested}
				getSuggestionValue={this.getSuggestionValue}
				renderSuggestion={this.renderSuggestion}
				highlightFirstSuggestion={true}
				focusInputOnSuggestionClick={true}
				onInputChange={this.handleInputChange}
				onClearSearch={this.clearSearch}
				onReloadTable={this.reloadTable}
			/>
		);
	}

	handleTextFieldPropertyUpdate = (property, value) => {
		const nextState = {};

		nextState[property] = value;

		this.setState(nextState);
	};

	handleDateChange = (property, value) => {
		let {startDate, endDate} = this.state;
		if (property === 'startDate') {
			startDate = value?value.split(' ')[0]:null;
			if (startDate) {
				startDate += ' 00:00:00';
			}
			if(endDate !== null){
				endDate = endDate.split(' ')[0];
				if (endDate) {
					endDate += ' 23:59:59';
				}
			}
		}
		if (property === 'endDate') {

			endDate = value?value.split(' ')[0]:null;
			if (endDate) {
				endDate += ' 23:59:59';
			}
			if(startDate !== null){
				startDate = startDate.split(' ')[0];
				if (startDate) {
					startDate += ' 00:00:00';
				}
			}
		}
		this.handleTextFieldPropertyUpdate(property, value);
		let values = [
			{
				value: {
					start: startDate,
					end: endDate,
				}
			}
		];		// Only send filter request if dates are valid
		if ((!startDate || startDate.match(dateValueRegex)) && (!endDate || endDate.match(dateValueRegex))) {
			this.doFilter(values, {name: 'date'});
		} else {
			this.doFilter([], {name: 'date'});
		}
	}

	renderDateRangeSearch = () => {
		const {startDate, endDate} = this.state;
		return (
			<div className="row dateRangeSearch">
				<div className="col-sm">
					<Paper>
						<Date
							label="On Or After Date"
							property={dateRangeProperties.startDate}
							value={startDate}
							update={this.handleDateChange}
							disabled={false}
						/>
					</Paper>
				</div>
				<div className="col-sm">
					<Paper>
						<Date
							label="On Or Before Date"
							property={dateRangeProperties.endDate}
							value={endDate}
							update={this.handleDateChange}
							disabled={false}
						/>
					</Paper>
				</div>
			</div>
		);
	}

	/**
	 *
	 * @param type
	 * @param {array} columns       Required table columns
	 * @param {array} extraColumns  Only required if additional hidden columns are available
	 * @param columnsAnchor
	 * @param handleOpen
	 * @param handleClose
	 * @param children
	 * @returns {*}
	 */
	renderHeaderRight = (type = 'Collection', columns, extraColumns = null, columnsAnchor = null, handleOpen = null, handleClose = null, children = null) => {
		//ml-auto columns
		return (
			<TableHeaderRight
				name={type}
				csvData={this.downloadData(columns)}
				onReload={this.reloadTable}
				onClear={this.clearFilters}
				showCSV={this.state.showCSV}
			>
				{children}
				{extraColumns && <div className="">
					{extraColumns.length > 0 &&
					<Button color="secondary"
					        outline={true}
					        aria-owns={columnsAnchor ? 'quick-transactions' : null}
					        aria-haspopup="true"
					        onClick={handleOpen('columnsAnchor')}
					        style={{marginLeft: '4px'}}
					>
						<i className={"fa fa-bars"}/>
					</Button>}
					{extraColumns.length > 0 &&
					<Menu
						id="quick-transactions"
						anchorEl={columnsAnchor}
						open={Boolean(columnsAnchor)}
						onClose={handleClose}
						getContentAnchorEl={null}
						anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
						transformOrigin={{horizontal: 'left', vertical: 'top'}}
					>
						{extraColumns.map((col, i) => {
							return (
								<MenuItem key={i} onClick={this.toggleColumn(col)}>
									<ListItemIcon>
										<i className={this.state[col.toggle] ? 'fa fa fa-check-square-o fa-lg' : 'fa fa-square-o fa-lg'}/>
									</ListItemIcon>
									<ListItemText inset primary={col.Header}/>
								</MenuItem>
							)
						})}
					</Menu>}
				</div>}
			</TableHeaderRight>
		)
	}

	toggleColumn = (col) => () => {
		this.setState({
			[col.toggle]: !this.state[col.toggle]
		});
		this.updateColumnsToLocalStorage({
			[col.toggle]: !this.state[col.toggle]
		});
	}


	handleOpen = (el) => event => {
		this.setState({
			[el]: event.currentTarget,
		});
	};

	handleClose = () => {
		this.setState({
			columnsAnchor: null
		});
	};
}
