import React from 'react';
import PropTypes from "prop-types";
import Framework from "@beardeddevops/react.framework";
import Types from "../../constants/Types";

export default class Address extends React.Component {
	static propTypes = {
		object: PropTypes.object.isRequired,
		properties: PropTypes.object.isRequired,
		onTextUpdate: PropTypes.func.isRequired,
		onSelectUpdate: PropTypes.func.isRequired,
		prefix: PropTypes.string,
		hasCounty: PropTypes.bool,
		notRequired: PropTypes.bool,
		disableSelect: PropTypes.bool
	};
	render() {
		let hasCounty = typeof this.props.hasCounty !== 'undefined' ? this.props.hasCounty : true;
		/** @type {Locations_Object} object */
		let object = this.props.object;
		/** @type {Locations_Properties} properties */
		let properties = this.props.properties;
		let {onTextUpdate, onSelectUpdate, prefix} = this.props;
		prefix = prefix || '';

		return <div>
			<div className={"row"}>
				<div className={"col-7"}>
					<div className="form-group">
						<Framework.Elements.TextField
							label={"Street 1"}
							property={properties[prefix + 'street_1']}
							value={object[prefix + 'street_1']}
							error={object[prefix + 'street_1_error']}
							update={onTextUpdate}
						/>
					</div>
				</div>
				<div className={"col-5"}>
					<div className="form-group">
						<Framework.Elements.TextField
							label={"Street 2"}
							property={properties[prefix + 'street_2']}
							value={object[prefix + 'street_2']}
							error={object[prefix + 'street_2_error']}
							update={onTextUpdate}
						/>
					</div>
				</div>
			</div>
			{hasCounty &&
			<div className={"row"}>
				<div className={"col-7"}>
					<div className="form-group">
						<Framework.Elements.TextField
							label={"City"}
							property={properties[prefix + 'city']}
							value={object[prefix + 'city']}
							error={object[prefix + 'city_error']}
							update={onTextUpdate}
						/>
					</div>
				</div>
				<div className={"col-5"}>
					<div className="form-group">
						<Framework.Elements.TextField
							label={"County"}
							property={properties[prefix + 'county']}
							value={object[prefix + 'county']}
							error={object[prefix + 'county_error']}
							update={onTextUpdate}
						/>
					</div>
				</div>
			</div>
			}
			{!hasCounty &&
			<div className="form-group">
				<Framework.Elements.TextField
					label={"City"}
					property={properties[prefix + 'city']}
					value={object[prefix + 'city']}
					error={object[prefix + 'city_error']}
					update={onTextUpdate}
				/>
			</div>
			}
			<div className={"row"}>
				<div className={"col-7"}>
					<div className="form-group" data-id="address-state">
						<Framework.Elements.Select
							shouldLoad={true}
							update={onSelectUpdate}
							property={properties[prefix + 'state']}
							value={object[prefix + 'state']}
							error={object[prefix + 'state_error']}
							model={Types.States._name}
							id={'short'}
							label={"State"}
							disabled={this.props?.disableSelect}
							nullable={!!this.props.notRequired}
							limit={null}
						/>
					</div>
				</div>
				<div className={"col-5"}>
					<div className="form-group">
						<Framework.Elements.ZipCode
							label={"Zip"}
							property={properties[prefix + 'zip']}
							value={object[prefix + 'zip']}
							error={object[prefix + 'zip_error']}
							update={onTextUpdate}
						/>
					</div>
				</div>
			</div>

		</div>

	}
}
