import React from "react";
import {withRouter} from "react-router";
import classNames from "classnames";

import ClipLoader from 'react-spinners/ClipLoader';
import Typography from '@material-ui/core/Typography';
import moment from "moment";

import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';

export default class RegisterObject extends Framework.Components.Object {
	constructor(props) {
		/**
		 *  @type {Registers_Object} this.state
		 */
		super(props, Types.Registers._name);

		this.state.locations = [];
		this.state.terminals = [];
	}

	// noinspection JSUnusedGlobalSymbols
	loadSelfAfter() {
		this.props.setCurrentAmount(this.state.amount_current);
	}

	form = () => {
		/** @type {Registers_Object} object */
		let object = this.state;
		/** @type {Registers_Properties} properties */
		let properties = this.model.properties;
		let exists = object.pkey && object.pkey.value;

		if( object.location && object.location.value === '' && object.locations.length > 0 ) {
			object.location.value = object.locations[0].pkey;
		}


		return (
			<div>
				<form>
					<fieldset>
						<h4 className="section-title">Register</h4>
						<div className={"row"}>
							<div className="col-md">
								<div className="form-group">
									<Framework.Elements.TextField
										name={"name"}
										label={"Name"}
										value={object.name}
										error={object.name_error}
										property={properties.name}
										update={this.handleTextFieldPropertyUpdate}
									/>
								</div>
								<div className="form-group">
									<Framework.Elements.Select
										text={'name'}
										label={"Location"}
										value={object.location}
										error={object.location_error}
										property={properties.location}
										update={this.handleSelectPropertyUpdate}
										model={Types.Locations._name}
										nullable={true}
										shouldLoad={this.state.loaded}
									/>
								</div>
								<div className="form-group">
									<Framework.Elements.Select
										shouldLoad={this.state.loaded}
										value={object.terminal}
										error={object.terminal_error}
										model={Types.Terminals._name}
										property={properties.terminal}
										label={'Terminal'}
										update={this.handleSelectPropertyUpdate}
										id={'id'}
										text={'description'}
										textHelper={'manufacturer&model'}
									/>
								</div>
								<div className="form-group">
									<Framework.Elements.TextField
										name={"amount_current"}
										label={object.pkey || object.pkey.value ? "Current Amount" : "Starting Amount"}
										value={object.amount_current}
										error={object.amount_current_error}
										property={properties.amount_current}
										update={this.handleTextFieldPropertyUpdate}
										//disabled={true}
									/>
								</div>

							</div>

						</div>
					</fieldset>
				</form>

				<div className={classNames("loader", {'active': object.loading})}>
					<ClipLoader color={"#4A4A4A"} loading={object.loading}/>
				</div>
			</div>
		)
	}
}
