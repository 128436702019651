import React from 'react';
import {Switch, Redirect} from 'react-router-dom';

import Collection from './components/Registers'
import Object from './components/Register'
import ErrorBoundaryRoute from "../../../../containers/ErrorBoundaryRoute";

export default ({account, match,locations}) => (
	<div>
		<Switch>
			<ErrorBoundaryRoute exact path={`${match.url}`}>
				<Collection locations={locations}/>
			</ErrorBoundaryRoute>

			<ErrorBoundaryRoute path={`${match.url}/:id`}>
				<Object account={account} locations={locations} baseUrl={`${match.url}`}/>
			</ErrorBoundaryRoute>

			<ErrorBoundaryRoute>
				<Redirect to={`${match.url}`}/>
			</ErrorBoundaryRoute>
		</Switch>
	</div>
);
