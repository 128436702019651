import React from 'react';
import Framework from "@beardeddevops/react.framework";
import TransactionsView from "components/Containers/TransactionsView"


export default class Transaction extends Framework.Components.View {

	constructor(props) {
		super(props);
	};

	render() {

		return (
			<TransactionsView
				history={this.props.history}
				transaction = {this.props.match.params.id}
			/>
		)
	}
};
