import React from "react";
import {withRouter} from "react-router";

import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';

class InventorySerializedObject extends Framework.Components.Object {
	constructor(props) {
		/**
		 *  @type {Inventories_Serialized_Object} this.state
		 */
		super(props, Types.Inventories_Serialized._name);
	}

	redirect = "/inventory/serialized";

	// noinspection JSUnusedGlobalSymbols
	handleCheckedChangeFor = event => {
		const prop = this.state[event.target.name];
		if (event.target.name === "serialized") {
			if (prop.value !== event.target.checked) {
				let serials = this.state.serials;
				serials.value = [""];
				this.setState({serials: serials});
			}
		}
		prop.value = event.target.checked;
		this.setState({[event.target.name]: prop});
	};

	render() {
		let properties = this.state;
		return (
			<div>
				<form>
					<fieldset>
						<div className={"row justify-content-center"}>
							<div className="col-6">
								<div className="form-group">
									<Framework.Elements.TextField name={"serial"}
									           label={"Serial Number"}
									           property={properties.serial}
									           update={(p) => this.setState({serial: p})}
									/>
								</div>
								<Framework.Elements.Checkbox name={"sold"}
								          label={"Sold"}
								          property={properties.sold}
								          update={(p) => this.setState({sold: p})}
								/>
								<div className="form-group">
									<Framework.Elements.TextField name={"notes"}
									           label={"Notes"}
									           property={properties.notes}
									           update={(p) => this.setState({notes: p})}
									/>
								</div>
							</div>
						</div>
					</fieldset>
				</form>
			</div>
		)
	}
}

export default withRouter(InventorySerializedObject);
