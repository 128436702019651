import React from 'react';
import {Switch, Redirect} from 'react-router-dom';
import ErrorBoundaryRoute from "../../../../containers/ErrorBoundaryRoute";

import Collection from './components/Skus'
import Object from './components/Sku'
import CollectionClass from "./components/Classes";
import ObjectClass from "./components/Class";

export default ({match, shouldReloadCart, scannedCode, locations,account}) => (
	<div>
		<Switch>
			<ErrorBoundaryRoute exact path={`${match.url}`}>
				<Collection
					baseUrl={`${match.url}`}
					locations={locations}
					account = {account}
					shouldReloadCart={shouldReloadCart}
					scannedCode={scannedCode}
				/>
			</ErrorBoundaryRoute>

			<ErrorBoundaryRoute exact path={`${match.url}/classes`}>
				<CollectionClass baseUrl={`${match.url}`}/>
			</ErrorBoundaryRoute>

			<ErrorBoundaryRoute exact path={`${match.url}/:id`}>
				<Object baseUrl={`${match.url}`} locations={locations}/>
			</ErrorBoundaryRoute>

			<ErrorBoundaryRoute exact path={`${match.url}/classes/:id`}>
				<ObjectClass baseUrl={`${match.url}/classes`}/>
			</ErrorBoundaryRoute>

			<ErrorBoundaryRoute>
				<Redirect to={`${match.url}`}/>
			</ErrorBoundaryRoute>
		</Switch>
	</div>
);
