import React from "react";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';

import API from "@beardeddevops/react.api";
import APPCONFIG from "constants/Config";
import Types from "constants/Types";
import Framework from "@beardeddevops/react.framework";

import ReactTable from "react-table";
import {Link} from "react-router-dom";
import {Card, CardContent} from "@material-ui/core";
import {Button} from "reactstrap";
import moment from "moment";




class Transactions extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Transactions._name, API.RequestType.READ_ALL);
		super(props, model);
		this.state.limit = 16;
		this.limit = 16;
	}

	getItems(props) {

			let allItems = 0;

			//Collect item entry quantities and map to new array
			let items = props.original.transaction_itemss.map((item) => {
				return item.quantity;
			})

			//Increment allItems with new array values
			items.forEach((item) => {
				allItems += item
			})

			//Products
			let products = props.original.transaction_productss.map((product) => {
				return 1;
			})

			products.forEach((product) => {
				allItems += product
			})

			//Consignments
			let consignments = props.original.transaction_consignmentss.map((consignment) => {
				return consignment.quantity;
			})

			consignments.forEach((consignment) => {
				allItems += consignment
			})

			return allItems;

	}

	render() {
		const {data, pages, loading,limit} = this.state;
	    let columns =
	      [
			{
				Header: "Date Time",
				id: "date",
				width: 180,
				accessor: d => {
					return moment(d.date).local().format("MM/DD/YYYY hh:mm A")
				}
			},
		    {
		      Header: "Order #",
			  id: "pkey",
		      accessor: r => r.pkey,
		    },
		    {
				//Todo: Can't sort this column... suggesting that we throw this logic on the backend somehow and return one field.
		      Header: "Method",
		      id: "method",
		      accessor: r => {
				let method = [];
				if (r.processor_amount !== "0.00") method.push("Card");
				if (r.cash_amount !== "0.00") method.push("Cash");
				if (r.check_amount !== "0.00") method.push("Check");
				if (r.other_amount !== "0.00") method.push("Other");
				return method.length === 1 ? method[0] :
					(method.length === 2 ? method[0] + "/" + method[1] : "Mixed");
			  },
		    },
		    {
		      Header: "Amount",
		      id: "amount",
		      accessor: r => "$ " + (parseFloat(r.amount)).toFixed(2),

		    },
		    {
		      Header: "Tax",
		      id: "tax_amount",
		      accessor: r => "$ " + (parseFloat(r.tax_amount)).toFixed(2),

		    },
		    {
		      Header: "Name",
		      id: "terminal_cardholder_name",
		      accessor: r => r.client ? r.client : r.terminal_cardholder_name,

		    },
		    {
		      Header: "Email",
		      id: "receipt_email",
		      accessor: r => r.receipt_email,

		    },
		    {
		      Header: "Description",
		      id: "description",
		      accessor: r => r.description,
		    },
		    {
			    //Todo: Might need a virtual column on the backend.. Not sure how to go about that (merging different items)
			    Header: "Items",
		      id: "items",
		      /** @var r.transaction_itemss */
		      /** @var r.transaction_productss */
		      /** @var r.transaction_consignmentss */
			    accessor: r => r.transaction_itemss,
			    Cell: props => this.getItems(props)


		    },
		      {
			      Header: "User",
			      id: "user",
			      /** @var r.users */
			      accessor: r => r.users != null ? r.users.name : (r.accounts.name_first+" "+ r.accounts.name_last),
			      Cell: props => props.original.users != null ?
				      <Link to={"/users/" + props.original.users.pkey}>{props.value}</Link> :
				      <Link to={"/settings"}>{props.value}</Link>
		      },
		      {
			      Header: "Refunded",
			      id: "refunded",
			      accessor: r => r.refunded.toString() === "true" ? "Refunded" : null
		      },
		    {
		      Header: "View",
			    sortable: false,
			    Cell: props => {
		      return (
		        <span>
		          <Link to={this.getItems(props) > 0 ? "/transactions/" + props.original.pkey : undefined}>
		            <Button color="primary pull-right" size={"sm"} block={true} disabled={!(this.getItems(props) > 0)}><i
		              className={"fa fa-shopping-cart"}/>&nbsp;&nbsp;View /
		              Refund</Button>
		          </Link>
		        </span>
		      );
		    }
	    }
	  ];


		return (
			<Card>
				<CardContent>
				<div className="box-body">

					<div className="table-bar">
						<div className="d-flex">
							{this.renderSearch("Transactions")}
							{this.renderHeaderRight("Transactions",columns)}
						</div>
					</div>

					<ReactTable
						key={this.state.key}
						columns= {columns}
						manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={data}
						pages={pages} // Display the total number of pages
						loading={loading} // Display the loading overlay when we need it
						loadingText="Requesting Data"
						onFetchData={this.fetchData} // Request new data when things change
						defaultSorted={[
							{
								id: this.model.sort,
								desc: this.model.order === 'desc'
							}
						]}
						expanderDefaults={{
							sortable: true,
							resizable: true,
							filterable: false,
							width: undefined
						}}
						showPagination= {true}
						showPaginationTop= {false}
						showPaginationBottom= {true}
						showPageSizeOptions= {true}
						defaultPageSize={limit}
						onPageSizeChange={this.onPageSizeChange}
						className="-striped -highlight"
					/>

				</div>
					{APPCONFIG.settings.isDev() ? null :
						<div className={classNames("tabled", "loader", {'active': loading})}>
							<ClipLoader color={"#4A4A4A"} loading={loading}/>
						</div>
					}
				</CardContent>
			</Card>
		)
	}
}
export default Framework.Components.withRootState(Transactions);


