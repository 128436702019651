import React from 'react';
import {Switch, Redirect} from 'react-router-dom';

import Collection from './components/Products';
import ErrorBoundaryRoute from "../../../../containers/ErrorBoundaryRoute";

export default ({ match }) => (
	<div>
		<Switch>
			<ErrorBoundaryRoute exact path={`${match.url}`}>
				<Collection />
			</ErrorBoundaryRoute>
			<ErrorBoundaryRoute>
				<Redirect to={`${match.url}`} />
			</ErrorBoundaryRoute>
		</Switch>
	</div>
);
