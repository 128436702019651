import React from 'react';
import {Switch, Redirect} from 'react-router-dom';

import ErrorBoundaryRoute from "../../../../containers/ErrorBoundaryRoute";
import Object from './components/Layaway'
import Collection from './components/Layaways'
export default ({match, locations}) => (
	<div>
		<Switch>
			<ErrorBoundaryRoute exact path={`${match.url}`}>
				<Collection locations={locations}/>
			</ErrorBoundaryRoute>

			<ErrorBoundaryRoute path={`${match.url}/:id`}>
				<Object locations={locations} baseUrl={`${match.url}`}/>
			</ErrorBoundaryRoute>

			<ErrorBoundaryRoute>
				<Redirect to={`${match.url}`}/>
			</ErrorBoundaryRoute>
		</Switch>
	</div>
);
